import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Select, MenuItem, InputAdornment, CircularProgress, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLoginPageLogic } from '../../containers/signInAndSignUpContainer/signInAndSignUpContainer';
import { inputBoxStyle, buttonStyle } from './SignInAndRegisterPage';
import Layout from './Layout';
import { yellowOwlMessages } from '../../Constants';

const SignUp: React.FC = () => {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({ input: '', otp: '', password: '', confirmPassword: '' });
  const {
    loginMode,
    setLoginMode,
    inputValue,
    setInputValue,
    otpSent,
    otp,
    setOtp,
    selectedCountryCode,
    setSelectedCountryCode,
    handleCountryCodeChange,
    handleSendOtp,
    handleVerifyOtp,
    handleVerifyOtpEmail,
    handleResendOtp,
    isOtpLoading,
    isOtpEmailLoading,
    isVerifyOtpLoading,
    isVerifyOtpEmailLoading,
    isResendOtpLoading,
    resendOtpTimer,
    password,
    setPassword,
    setPasswordScreen,
    confirmPassword,
    handleSetPassword,
    isSetPasswordLoading,
    setConfirmPassword,
    showPassword,
    setShowPassword,
    setPasswordResetSuccess,
    passwordResetSuccess,
    showConfirmPassword,
    setShowConfirmPassword,
  } = useLoginPageLogic();

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem('phoneNumber');
    if (storedPhoneNumber) {
      const storedCountryCode = storedPhoneNumber.slice(0, 3);
      const storedPhone = storedPhoneNumber.slice(3);
      setSelectedCountryCode(storedCountryCode);
      setInputValue(storedPhone);
    }
  }, []);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleOtpVerification = async () => {
    if (otp.length !== 6) {
      setErrors((prevErrors) => ({ ...prevErrors, otp: 'Please enter a 6-digit OTP.' }));
      return;
    }
    if (loginMode === 'phoneNumber') {
      await handleVerifyOtp();
    } else {
      await handleVerifyOtpEmail();
    }
    handleNextStep();
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleLoginModeChange = (mode: 'phoneNumber' | 'email') => {
    setLoginMode(mode);
    localStorage.setItem('loginMode', mode);

    if (mode === 'phoneNumber') {
      const phoneWithCountryCode = `${selectedCountryCode}${inputValue}`;
      localStorage.setItem('phoneNumber', phoneWithCountryCode);
    } else {
      localStorage.setItem('email', inputValue);
    }

    handleNextStep();
  };

  const validateInput = () => {
    let inputError = '';
    let otpError = '';
    let passwordError = '';
    let confirmPasswordError = '';

    if (loginMode === 'phoneNumber') {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(inputValue)) {
        inputError = 'Please enter a valid 10-digit phone number.';
      }
    } else if (loginMode === 'email') {
      const emailRegex = /^[\w-.]+@[\w-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(inputValue)) {
        inputError = 'Please enter a valid email address.';
      }
    }

    if (otpSent && (!otp || !/^\d{6}$/.test(otp))) {
      otpError = 'OTP must be a 6-digit number.';
    }

    if (setPasswordScreen) {
      if (!password) {
        passwordError = 'Password cannot be empty.';
      } else if (password.length < 4 || password.length > 10) {
        passwordError = 'Password must be between 4 and 10 characters long.';
      }

      if (password !== confirmPassword) {
        confirmPasswordError = 'Passwords do not match.';
      }
    }

    // Update errors in state
    setErrors({
      input: inputError,
      otp: otpError,
      password: passwordError,
      confirmPassword: confirmPasswordError,
    });

    // Return validation status
    return !inputError && !otpError && !passwordError && !confirmPasswordError;
  };

  const handleSendOtpClick = () => {
    if (validateInput()) {
      handleSendOtp();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (step === 1) {
        if (loginMode === 'phoneNumber' || loginMode === 'email') {
          handleLoginModeChange(loginMode);
        }
      } else if (step === 2 && !otpSent) {
        if (validateInput()) {
          handleSendOtp();
        }
      } else if (otpSent && !setPasswordScreen) {
        handleOtpVerification();
      } else if (setPasswordScreen) {
        handleSetPassword();
      }
    }
  };

  return (
    <Layout
      heading={yellowOwlMessages.SIGN_UP}
      passwordResetSuccess={passwordResetSuccess}
      setPasswordResetSuccess={setPasswordResetSuccess}
    >
      <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
        {step === 1 && (
          <>
            <Button
              variant='contained'
              onClick={() => {
                handleLoginModeChange('phoneNumber');
              }} // Move mode selection here
              sx={{ ...buttonStyle, marginBottom: '10px' }}
            >
              {yellowOwlMessages.PhoneNo}
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                handleLoginModeChange('email');
              }} // Move mode selection here
              sx={buttonStyle}
            >
              {yellowOwlMessages.EMAIL}
            </Button>
          </>
        )}
        {step === 2 && !otpSent && (
          <>
            {loginMode === 'phoneNumber' && (
              <Box sx={{ display: 'flex', gap: 1, marginBottom: '10px', alignItems: 'flex-start' }}>
                <Select
                  value={selectedCountryCode}
                  onChange={handleCountryCodeChange}
                  sx={{ width: '30%', '& .MuiSelect-icon': { color: '#4682B4' } }}
                >
                  <MenuItem value='+91'>+91 (India)</MenuItem>
                  <MenuItem value='+1'>+1 (USA)</MenuItem>
                  <MenuItem value='+44'>+44 (UK)</MenuItem>
                </Select>
                <TextField
                  placeholder={yellowOwlMessages.ENTER_PHONE_NUMBER}
                  value={inputValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setInputValue(value);
                      const phoneWithCountryCode = `${selectedCountryCode}${value}`;
                      localStorage.setItem('phoneNumber', phoneWithCountryCode); // Save phone number
                    }
                  }}
                  fullWidth
                  error={!!errors.input}
                  helperText={errors.input}
                  onKeyPress={handleKeyPress}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <PhoneIcon sx={{ color: '#4682B4' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            )}
            {loginMode === 'email' && (
              <TextField
                placeholder={yellowOwlMessages.ENTER_EMAIL}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                fullWidth
                error={!!errors.input}
                helperText={errors.input}
                onKeyPress={handleKeyPress}
                sx={{ marginBottom: '10px' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <EmailIcon sx={{ color: '#4682B4' }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Button
              variant='contained'
              onClick={handleSendOtpClick}
              sx={buttonStyle}
              disabled={isOtpLoading || isOtpEmailLoading}
            >
              {isOtpLoading || isOtpEmailLoading ? <CircularProgress size={24} /> : yellowOwlMessages.SEND_OTP}
            </Button>
          </>
        )}
        {otpSent && !setPasswordScreen && (
          <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
            <TextField
              placeholder={yellowOwlMessages.ENTER_OTP}
              value={otp}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,6}$/.test(value)) {
                  setOtp(value);
                }
              }}
              fullWidth
              error={!!errors.otp}
              helperText={errors.otp}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
            />
            <Button
              variant='contained'
              onClick={handleOtpVerification}
              sx={buttonStyle}
              disabled={isVerifyOtpLoading || isVerifyOtpEmailLoading}
            >
              {isVerifyOtpLoading || isVerifyOtpEmailLoading ? (
                <CircularProgress size={24} />
              ) : (
                yellowOwlMessages.VERIFY_OTP
              )}
            </Button>
            <Button
              variant='text'
              onClick={handleResendOtp}
              sx={{ fontSize: '1rem', color: '#4682B4' }}
              disabled={resendOtpTimer > 0 || isResendOtpLoading}
            >
              {isResendOtpLoading ? (
                <CircularProgress size={24} />
              ) : (
                `${yellowOwlMessages.RESEND_OTP} ${resendOtpTimer > 0 ? `(${resendOtpTimer}s)` : ''}`
              )}
            </Button>
          </Box>
        )}
        {setPasswordScreen && (
          <>
            <TextField
              placeholder={yellowOwlMessages.ENTER_PASSWORD}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10) {
                  setPassword(value);
                }
              }}
              fullWidth
              error={!!errors.password}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={togglePasswordVisibility}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder={yellowOwlMessages.CONFIRM_PASSWORD}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 10) {
                  setConfirmPassword(value);
                }
              }}
              fullWidth
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword || yellowOwlMessages.PASSWORD_HELP_TEXT}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={toggleConfirmPasswordVisibility}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant='contained' onClick={handleSetPassword} sx={buttonStyle} disabled={isSetPasswordLoading}>
              {isSetPasswordLoading ? <CircularProgress size={24} /> : yellowOwlMessages.SET_PASSWORD}
            </Button>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default SignUp;
