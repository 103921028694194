import React from 'react';
import { Box, Typography, Button, Modal, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import yowlLogoImage from '../../assets/images/YowlLogo.svg';
import {
  containerStyle,
  leftContainerStyle,
  boxStyle,
  fixedContainerStyle,
  headingStyle,
  titleStyling,
  subtitle,
  forgotPassWordSucessModalStyle,
} from './SignInAndRegisterPage';
import { yellowOwlMessages } from '../../Constants';

interface LayoutProps {
  children: React.ReactNode;
  heading: string;
  passwordResetSuccess: boolean;
  setPasswordResetSuccess: (value: boolean) => void;
}

const Layout: React.FC<LayoutProps> = ({ children, heading, passwordResetSuccess, setPasswordResetSuccess }) => {
  const navigate = useNavigate();

  const handleModalClose = () => {
    setPasswordResetSuccess(false);
    localStorage.clear();
    const currentPath = window.location.pathname;
    if (currentPath.includes('assessment')) {
      navigate('/assessment/login');
    } else {
      navigate('/login');
    }
  };

  return (
    <Box sx={containerStyle}>
      <Box sx={leftContainerStyle}>
        <Typography variant='h2' sx={titleStyling}>
          {yellowOwlMessages.YELLOW_OWL}
        </Typography>
        <Typography variant='h6' sx={subtitle}>
          {yellowOwlMessages.YELLOW_OWL_DESCRIPTION}
        </Typography>
      </Box>
      <Box sx={boxStyle}>
        <Typography sx={headingStyle}>{heading}</Typography>
        <Box sx={{ textAlign: 'center', marginTop: { xs: '10px', md: '30px' } }}>
          <img
            src={yowlLogoImage}
            alt='Yellow Owl Frame'
            style={{
              width: '100%',
              maxWidth: '300px',
              height: 'auto',
              display: 'block',
            }}
          />
        </Box>
        <Box sx={fixedContainerStyle}>{children}</Box>
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              color: 'green',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Link href='https://www.theyellowowl.com/privacy-policy' sx={{ color: 'brown' }}>
              {yellowOwlMessages.PRIVACY_POLICY}
            </Link>
            |
            <Link href='https://www.theyellowowl.com/about' sx={{ color: 'brown' }}>
              {yellowOwlMessages.ABOUT_US}
            </Link>
            |
            <Link href='https://www.theyellowowl.com/contact' sx={{ color: 'brown' }}>
              {yellowOwlMessages.CONTACT_US}
            </Link>
          </Typography>
        </Box>
        <Modal open={passwordResetSuccess} onClose={() => setPasswordResetSuccess(false)}>
          <Box sx={forgotPassWordSucessModalStyle}>
            <Typography sx={{ marginBottom: '20px' }}>{yellowOwlMessages.PASSWORD_RESET_SUCCESS}</Typography>
            <Button
              variant='contained'
              sx={{
                backgroundColor: '#4682B4',
                '&:hover': { backgroundColor: '#6A8FB5' },
                textTransform: 'none',
                borderRadius: '8px',
              }}
              onClick={handleModalClose}
            >
              {yellowOwlMessages.OK}
            </Button>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Layout;
