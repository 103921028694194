import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import noMagazinesBackgroundImage from '../../assets/images/no-magazines-background.svg';
import { gradeToAgeMap, yellowOwlMessages } from '../../Constants';
import { useNavigate } from 'react-router-dom';
import { Magazine } from '@yellow-owl/client-sdk';
import BookSelfImage from '../../assets/images/self-image.svg';
import { useEffect, useRef, useState } from 'react';
import { useEventSenders } from '../../services/userEventsService';
import { Player } from '@lottiefiles/react-lottie-player';
import lockAnimation from '../../assets/json/lock.json';
import { SUBSCRIBER } from '../../context/LocalStorageContext';
import issueDateAnimation from '../../assets/json/issue-date.json';
import paymentAnimation from '../../assets/json/payment.json';
import libraryBackground from '../../assets/images/library-background.svg';
import { UserProfileComponent } from '../common/UserProfileComponent';
import LogoutComponent from '../common/LogoutComponent';
import owlHeader from '../../assets/images/Yellow owl Frame.png';

const magazinesBackground: SxProps<Theme> = {
  background: `url(${libraryBackground}) no-repeat`,
  backgroundSize: 'cover',
  backgroundPosition: 'top left',
  height: '100vh',
  position: 'fixed',
};

const commonContent: SxProps<Theme> = {
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Livvic-Bold',
  lineHeight: '72px',
  fontStyle: 'normal',
  fontWeight: 700,
  color: '#3C3077',
  transform: 'translateY(0%)',
};

const navigationButton: SxProps<Theme> = {
  width: '65%',
  marginBottom: '10px',
  padding: '15px',
  borderRadius: '10px',
  background: '#4DB6AC',
  display: 'flex',
  justifyContent: 'center',
  color: 'white',
  textTransform: 'none',
  fontFamily: 'Livvic-Bold',
  fontSize: '1.2rem',
  '&:hover': {
    background: '#3B9189',
  },
};

const noMagazinesBackground: SxProps<Theme> = {
  background: '#6AC6BF',
  height: '100vh',
};

const selfsContainer: SxProps<Theme> = {
  height: '90vh',
  overflowX: 'hidden',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

const imageContainer: SxProps<Theme> = {
  position: 'relative',
  display: 'inline-block',
};

const blurEffect: SxProps<Theme> = {
  filter: 'blur(1.3px)',
};

const lockOverlay: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10,
  pointerEvents: 'none',
};

const magazineContainer: SxProps<Theme> = {
  background: 'rgba(255, 255, 255, 0.8)',
  padding: '16px',
  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)',
  height: '100vh',
};

const allCenterFlex: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const headerBox: SxProps<Theme> = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const yellowText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '5vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
};

const imgStyle = { width: '5vw', marginLeft: '14px' };

const owlText: SxProps<Theme> = {
  fontFamily: 'MacondoRegular',
  fontWeight: '400',
  fontSize: '5vh',
  lineHeight: '16px',
  fontStyle: 'normal',
  color: '#3C3077',
  marginLeft: '14px',
};

const noMagazinesColumnLeft: SxProps<Theme> = {
  fontWeight: 400,
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-evenly',
};

const noMagazinesColumn: SxProps<Theme> = {
  fontWeight: 400,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const gradeSelectStyles: SxProps<Theme> = {
  minWidth: 120,
  backgroundColor: '#46B8B0',
  borderRadius: '30px',
};

const gradeSelectInputStyles: SxProps<Theme> = {
  color: 'white',
  '& .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'transparent' },
  '& .MuiSvgIcon-root': { color: 'white' },
};

const cardStyle = {
  backgroundColor: '#4DB6AC',
  borderRadius: '10px',
  padding: '10px',
  marginTop: '10px',
};

const bookInfoStyle = {
  fontFamily: 'Livvic-Bold',
  fontWeight: 'bold',
  color: 'white',
};

const lottiePlayerStyle = { height: '200px', width: '200px', margin: '0 auto' };

const lockPlayerStyle = { height: '50px', width: '50px' };

const renderMagazineSectionStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  marginBottom: '70px',
};

const renderMagazineListStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-around',
};

const gridContainerStyles = {
  width: '60vw',
  marginTop: '1vh',
  direction: 'row',
  justifyContent: 'space-evenly',
};

const gridItemStyles = {
  textAlign: 'center',
  justifyContent: 'space-between',
};

const userProfileComponentStyles = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginRight: '60px',
};

export type ExploreCoursesPageComponentProps = {
  magazines: Magazine[];
  isFirst: boolean;
  isLast: boolean;
  onClickScrollDown(): void;
  onClickScrollUp(): void;
  selectedGrade: any;
  onGradeChange: any;
};

export const ExploreMagazinesPage: React.FC<ExploreCoursesPageComponentProps> = ({
  magazines,
  isFirst,
  isLast,
  onClickScrollUp,
  onClickScrollDown,
  selectedGrade,
  onGradeChange,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState<React.ReactNode>('');
  const [isTeacher, setIsTeacher] = useState(false);

  const navigate = useNavigate();
  const { sendLibraryPageEvent, sendMagazineLockedEvent } = useEventSenders();
  const eventSentRef = useRef(false);
  const bookShelfContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const userType = localStorage.getItem('userType');
    setIsTeacher(userType === 'teacher');
  }, []);

  useEffect(() => {
    const categoryId = localStorage.getItem('categoryId');
    if (!eventSentRef.current && magazines.length > 0 && categoryId) {
      sendLibraryPageEvent(categoryId, '');
      eventSentRef.current = true;
    }
  }, [magazines, sendLibraryPageEvent]);

  const handleGradeChange = (event: SelectChangeEvent) => {
    onGradeChange(event.target.value);
  };

  const calculateRemainingDays = (issueDate: Date) => {
    const today = new Date();
    const timeDiff = issueDate.getTime() - today.getTime();
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysDiff >= 0 ? daysDiff : 0;
  };

  const handleMagazineClick = (magazine: Magazine) => {
    const isSubscriber = localStorage.getItem(SUBSCRIBER);
    const isSubscribed = isSubscriber === 'true';
    const categoryId = localStorage.getItem('categoryId');

    if (magazine.isLocked) {
      sendMagazineLockedEvent(categoryId!, magazine.id.toString());

      if (isSubscribed) {
        const issueDate = new Date(magazine.issueDate!);
        const remainingDays = calculateRemainingDays(issueDate);
        setDialogTitle(yellowOwlMessages.UH_OH);
        setDialogContent(
          <>
            <Player autoplay loop src={issueDateAnimation} style={lottiePlayerStyle} />
            <p style={{ margin: '0', textAlign: 'center' }}>
              {remainingDays} day{remainingDays !== 1 ? 's' : ''} left to unlock
            </p>
          </>
        );
      } else {
        setDialogTitle(yellowOwlMessages.SUBSCRIPTION);
        setDialogContent(
          <>
            <Player autoplay loop src={paymentAnimation} style={lottiePlayerStyle} />
            <p>Please purchase the plan to unlock the magazines</p>
          </>
        );
      }
      setOpenDialog(true);
    } else {
      navigate({
        pathname: '/magazine/cover',
        search: `?id=${magazine.id}&coverPicUrl=${magazine.coverPicUrl}&name=${encodeURIComponent(magazine.title)}`,
      });
    }
  };

  const renderMagazineSection = (startIndex: number, endIndex: number) => (
    <div className='yow-book-self-wrapper' style={renderMagazineSectionStyles}>
      <div className='yow-book-list' style={renderMagazineListStyles}>
        <Grid container sx={gridContainerStyles}>
          {magazines.slice(startIndex, endIndex + 1).map((magazine, index) => (
            <Grid item xs={3} key={startIndex + index} sx={gridItemStyles}>
              <div className='mt-2 yow-book-card'>
                <Box sx={imageContainer}>
                  <Box
                    component='img'
                    src={magazine.coverPicUrl}
                    className='yow-lib-book'
                    onClick={() => handleMagazineClick(magazine)}
                    alt={`Magazine ${startIndex + index + 1}`}
                    sx={{
                      width: '100%',
                      height: '30vh',
                      cursor: 'pointer',
                      ...(magazine.isLocked ? blurEffect : {}),
                    }}
                  />
                  {magazine.isLocked && (
                    <Box sx={lockOverlay}>
                      <Player autoplay loop src={lockAnimation} style={lockPlayerStyle} />
                    </Box>
                  )}
                </Box>
                <div className='card text-center' style={cardStyle}>
                  <div className='book-info'>
                    <p className='m-0' style={bookInfoStyle}>
                      {magazine.title}
                    </p>
                  </div>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
      {magazines.length > startIndex && (
        <div className='yow-self'>
          <img src={BookSelfImage} className='w-100' alt='Bookshelf' />
        </div>
      )}
    </div>
  );

  return (
    <Box>
      {magazines.length === 0 ? (
        <>
          <Grid container sx={noMagazinesBackground}>
            <Grid item xs={11} sx={allCenterFlex}>
              <Box sx={headerBox}>
                <Typography sx={yellowText} variant='body2'>
                  Yellow
                </Typography>
                <img src={owlHeader} style={imgStyle} alt='yellow-owl-header' />
                <Typography sx={owlText} variant='body2'>
                  Owl
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1} sx={allCenterFlex}>
              <Box sx={{ display: 'flex', flexDirection: 'row', marginRight: '170px' }}>
                <UserProfileComponent />
                <LogoutComponent />
              </Box>
            </Grid>
            <Grid sx={noMagazinesColumnLeft} item xs={6}>
              <Stack>
                {isTeacher && (
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      mb: 25,
                      mr: 4,
                    }}
                  >
                    <FormControl sx={gradeSelectStyles}>
                      <InputLabel id='grade-select-label' style={{ fontFamily: 'Schoolbell' }}>
                        Grade
                      </InputLabel>
                      <Select
                        labelId='grade-select-label'
                        id='grade-select'
                        value={selectedGrade}
                        label='Grade'
                        onChange={handleGradeChange}
                        sx={gradeSelectInputStyles}
                      >
                        {Object.keys(gradeToAgeMap).map((grade) => (
                          <MenuItem key={grade} value={Number(grade)}>
                            <span style={{ fontFamily: 'Schoolbell' }}> Grade {grade}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )}
                {isTeacher ? (
                  <Typography
                    align={'center'}
                    gutterBottom
                    color={'#3C3077'}
                    fontWeight={'600'}
                    variant={'h3'}
                    mb={'30'}
                  >
                    {yellowOwlMessages.NO_MAGAZINES_AVAILABLE}
                  </Typography>
                ) : (
                  <Typography align={'center'} gutterBottom color={'#3C3077'} fontWeight={'600'} variant={'h3'}>
                    {yellowOwlMessages.NO_ISSUES_AVAILABLE}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid sx={noMagazinesColumn} item xs={6}>
              <img src={noMagazinesBackgroundImage} alt='noMagazinesBackground' />
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container sx={magazinesBackground}>
          {/* Left Panel */}
          <Grid item xs={4}>
            <Box sx={commonContent}>
              <Button sx={navigationButton} onClick={() => navigate('/community-wall')}>
                Community Wall
              </Button>
              <Button sx={navigationButton} onClick={() => navigate('/user-profile')}>
                Leaderboard
              </Button>
            </Box>
          </Grid>

          {/* Right Panel */}
          <Grid item xs={8}>
            <Box sx={magazineContainer}>
              {isTeacher ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDiirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '10px',
                  }}
                >
                  <FormControl sx={{ minWidth: 120, backgroundColor: '#6AC6BF', borderRadius: '30px' }}>
                    <Select
                      labelId='grade-select-label'
                      value={selectedGrade}
                      label='Grade'
                      onChange={handleGradeChange}
                      sx={gradeSelectInputStyles}
                    >
                      {Object.keys(gradeToAgeMap).map((grade) => (
                        <MenuItem key={grade} value={Number(grade)}>
                          Grade {grade}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box sx={userProfileComponentStyles}>
                    <UserProfileComponent />
                  </Box>
                </Box>
              ) : (
                <Box sx={userProfileComponentStyles}>
                  <UserProfileComponent />
                </Box>
              )}

              <Stack ref={bookShelfContainerRef} alignItems='center' sx={selfsContainer}>
                {!isFirst && (
                  <Button variant='contained' onClick={onClickScrollUp}>
                    <ExpandLessIcon />
                  </Button>
                )}

                {renderMagazineSection(0, 2)}
                {renderMagazineSection(3, 5)}
                {renderMagazineSection(6, 8)}

                {!isLast && (
                  <Button variant='contained' onClick={onClickScrollDown}>
                    <ExpandMoreIcon />
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContent}</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
