import React, { useEffect, useState } from 'react';
import { Box, Select, MenuItem, TextField, InputAdornment, Button, CircularProgress, IconButton } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLoginPageLogic } from '../../containers/signInAndSignUpContainer/signInAndSignUpContainer';
import { inputBoxStyle, buttonStyle } from './SignInAndRegisterPage';
import Layout from './Layout';
import { yellowOwlMessages } from '../../Constants';

const ForgotPassword: React.FC = () => {
  const [errors, setErrors] = useState({ input: '', otp: '', password: '', confirmPassword: '' });
  const {
    loginMode,
    setLoginMode,
    inputValue,
    setInputValue,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    selectedCountryCode,
    handleCountryCodeChange,
    otpSent,
    otp,
    setOtp,
    setPasswordScreen,
    isForgotPasswordVerifyLoading,
    isVerifyOtpLoading,
    isVerifyOtpEmailLoading,
    isResendOtpLoading,
    setForgotPasswordMode,
    isSetPasswordLoading,
    resendOtpTimer,
    handleForgotPasswordVerify,
    handleVerifyOtp,
    handleVerifyOtpEmail,
    handleResendOtp,
    handleSetPassword,
    passwordResetSuccess,
    setPasswordResetSuccess,
  } = useLoginPageLogic();

  useEffect(() => {
    setForgotPasswordMode(true);
    const storedLoginMode = localStorage.getItem('loginMode');
    if (storedLoginMode) {
      setLoginMode(storedLoginMode as 'phoneNumber' | 'email' | null);
    }
  }, [setLoginMode]);

  const validateInput = () => {
    let inputError = '';
    let otpError = '';
    let passwordError = '';
    let confirmPasswordError = '';

    if (loginMode === 'phoneNumber') {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(inputValue)) {
        inputError = 'Please enter a valid 10-digit phone number.';
      }
    } else if (loginMode === 'email') {
      const emailRegex = /^[\w-.]+@[\w-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(inputValue)) {
        inputError = 'Please enter a valid email address.';
      }
    }

    if (otpSent && (otp.length !== 6 || !/^\d{6}$/.test(otp))) {
      otpError = 'OTP must be exactly 6 digits.';
    }

    if (setPasswordScreen) {
      if (password.length < 4 || password.length > 10) {
        passwordError = 'Password must be between 4 and 10 characters.';
      }
      if (confirmPassword.length < 4 || confirmPassword.length > 10) {
        confirmPasswordError = 'Confirm Password must be between 4 and 10 characters.';
      }
    }

    setErrors({ input: inputError, otp: otpError, password: passwordError, confirmPassword: confirmPasswordError });

    return !inputError && !otpError && !passwordError && !confirmPasswordError;
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (!otpSent) {
        handleForgotPasswordVerifyClick();
      } else if (setPasswordScreen) {
        handleSetPasswordClick();
      } else {
        if (loginMode === 'email') {
          handleVerifyOtpEmailClick();
        } else {
          handleVerifyOtpClick();
        }
      }
    }
  };

  const handleForgotPasswordVerifyClick = () => {
    if (validateInput()) {
      handleForgotPasswordVerify();
    }
  };

  const handleVerifyOtpClick = () => {
    if (validateInput()) {
      handleVerifyOtp();
    }
  };

  const handleVerifyOtpEmailClick = () => {
    if (validateInput()) {
      handleVerifyOtpEmail();
    }
  };

  const handleSetPasswordClick = () => {
    if (validateInput()) {
      handleSetPassword();
    }
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setInputValue(value);
      const fullPhoneNumber = `${selectedCountryCode}${value}`;
      localStorage.setItem('phoneNumber', fullPhoneNumber);
    }
  };

  return (
    <Layout
      heading={yellowOwlMessages.FORGOT_PASSWORD}
      passwordResetSuccess={passwordResetSuccess}
      setPasswordResetSuccess={setPasswordResetSuccess}
    >
      <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
        {!otpSent && loginMode === 'phoneNumber' && (
          <Box sx={{ display: 'flex', gap: 1, marginBottom: '10px' }}>
            <Select
              value={selectedCountryCode}
              onChange={handleCountryCodeChange}
              sx={{ width: '30%', '& .MuiSelect-icon': { color: '#4682B4' } }}
            >
              <MenuItem value='+91'>+91 (India)</MenuItem>
              <MenuItem value='+1'>+1 (USA)</MenuItem>
              <MenuItem value='+44'>+44 (UK)</MenuItem>
            </Select>
            <TextField
              placeholder={yellowOwlMessages.ENTER_PHONE_NUMBER}
              value={inputValue}
              onChange={handlePhoneNumberChange}
              fullWidth
              error={!!errors.input}
              helperText={errors.input}
              onKeyPress={handleKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneIcon sx={{ color: '#4682B4' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        )}
        {!otpSent && loginMode === 'email' && (
          <TextField
            placeholder={yellowOwlMessages.ENTER_EMAIL}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            fullWidth
            error={!!errors.input}
            helperText={errors.input}
            onKeyPress={handleKeyPress}
            sx={{ marginBottom: '10px' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon sx={{ color: '#4682B4' }} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {!otpSent && (
          <Button
            variant='contained'
            onClick={handleForgotPasswordVerifyClick}
            sx={buttonStyle}
            disabled={isForgotPasswordVerifyLoading}
          >
            {isForgotPasswordVerifyLoading ? <CircularProgress size={24} /> : yellowOwlMessages.VERIFY}
          </Button>
        )}

        {otpSent && !setPasswordScreen && (
          <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
            <TextField
              placeholder={yellowOwlMessages.ENTER_OTP}
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              fullWidth
              error={!!errors.otp}
              helperText={errors.otp}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
            />
            <Button
              variant='contained'
              onClick={loginMode === 'phoneNumber' ? handleVerifyOtpClick : handleVerifyOtpEmailClick}
              sx={buttonStyle}
              disabled={isVerifyOtpLoading || isVerifyOtpEmailLoading}
            >
              {isVerifyOtpLoading || isVerifyOtpEmailLoading ? (
                <CircularProgress size={24} />
              ) : (
                yellowOwlMessages.VERIFY_OTP
              )}
            </Button>
            <Button
              variant='text'
              onClick={handleResendOtp}
              sx={{ fontSize: '1rem', color: '#4682B4' }}
              disabled={resendOtpTimer > 0 || isResendOtpLoading}
            >
              {isResendOtpLoading ? (
                <CircularProgress size={24} />
              ) : (
                `${yellowOwlMessages.RESEND_OTP} ${resendOtpTimer > 0 ? `(${resendOtpTimer}s)` : ''}`
              )}
            </Button>
          </Box>
        )}

        {setPasswordScreen && (
          <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
            <TextField
              placeholder={yellowOwlMessages.ENTER_PASSWORD}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              placeholder={yellowOwlMessages.CONFIRM_PASSWORD}
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle confirm password visibility'
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge='end'
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant='contained'
              onClick={handleSetPasswordClick}
              sx={buttonStyle}
              disabled={isSetPasswordLoading}
            >
              {isSetPasswordLoading ? <CircularProgress size={24} /> : yellowOwlMessages.SET_PASSWORD}
            </Button>
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default ForgotPassword;
