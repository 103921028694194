import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type ConfirmationModalType = {
  title: string;
  content: string;
  open: boolean;
  onClose: (value: boolean) => void;
};

export default function ConfirmationModal(props: ConfirmationModalType) {
  const { title, content, open, onClose } = props;

  const handleCancel = () => {
    onClose(false);
  };

  const handleOk = () => {
    onClose(true);
  };

  return (
    <Dialog keepMounted open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions sx={{ marginBottom: '10px' }}>
        <Button sx={{ color: '#46B8B0' }} autoFocus onClick={handleCancel} disableElevation>
          Cancel
        </Button>
        <Button sx={{ color: '#46B8B0' }} onClick={handleOk} disableElevation>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
