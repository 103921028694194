import React from 'react';
import { Box, Button, Typography, Modal, Link, SxProps, Theme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import backgroundImage from '../../assets/images/LandingPgaeBg.svg';
import { useLoginPageLogic } from '../../containers/signInAndSignUpContainer/signInAndSignUpContainer';
import yowlLogoImage from '../../assets/images/YowlLogo.svg';
import Login from './Login';
import ForgotPassword from './forgotPassword';
import { Registration } from './registration';
import SignUp from './signUp';
import { yellowOwlMessages } from '../../Constants';

export const containerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', md: 'row' },
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
};
export const forgotPassWordSucessModalStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '300px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: 2,
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};
export const leftContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: { xs: 'center', md: 'flex-start' },
  justifyContent: 'center',
  padding: 4,
  color: '#6AC6BE',
  width: { xs: '100%', md: '30%' },
  textAlign: { xs: 'center', md: 'left' },
  marginTop: { xs: -25, md: 0 },
  marginBottom: { xs: 5, md: 0 },
};

export const boxStyle: SxProps<Theme> = {
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  padding: { xs: 3, md: 4 },
  borderRadius: { xs: '16px', md: '8px' },
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginRight: { xs: 0, md: 10 },
  marginLeft: { xs: 0, md: 20 },
  width: { xs: '90%', md: '25%' },
  marginTop: { xs: 0, md: 0 },
};

export const fixedContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 2,
  width: '100%',
  maxWidth: '400px',
  marginTop: { xs: '0', md: '40px' },
  marginBottom: { xs: '20px', md: '40px' },
};

export const headingStyle: SxProps<Theme> = {
  color: 'brown',
  fontSize: { xs: '1.5rem', md: '2rem' },
  textAlign: 'center',
  marginTop: { xs: 1, md: 4 }, // Reduced gap for small screens
  fontFamily: 'Fredoka',
};

export const buttonStyle: SxProps<Theme> = {
  textTransform: 'none',
  fontSize: { xs: '0.8rem', md: '1rem' },
  width: { xs: '200px', md: '250px' },
  height: { xs: '40px', md: '50px' },
  color: 'white',
  borderRadius: '8px',
  boxShadow: '5px 4px 0px rgb(81, 165, 158)',
  backgroundColor: '#6AC6BE',
  fontFamily: 'Fredoka',
  '&:hover': {
    backgroundColor: 'white',
    color: '#6AC6BE',
  },
};

export const inputBoxStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  width: '300px',
  marginTop: { xs: '10px', md: '20px' },
};

export const titleStyling: SxProps<Theme> = {
  color: '#6AC6BE',
  fontFamily: 'Fredoka',
  textAlign: { xs: 'center', md: 'left' },
  fontSize: { xs: '1rem', md: '3rem' },
};

export const subtitle: SxProps<Theme> = {
  fontFamily: 'Fredoka',
  color: 'black',
  textAlign: { xs: 'center', md: 'left' },
  fontSize: { xs: '1rem', md: '1.5rem' },
};
const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const {
    loginMode,
    setLoginMode,
    showLoginOptions,
    setShowLoginOptions,
    showSignUpOptions,
    setShowSignUpOptions,
    additionalFields,
    setAdditionalFields,
    setSetPasswordScreen,
    forgotPasswordMode,
    setForgotPasswordMode,
    passwordResetSuccess,
    setPasswordResetSuccess,
    pathClick,
    handlePathClick,
    getHeading,
  } = useLoginPageLogic();

  const handleNavigation = (path: string) => {
    const currentPath = window.location.hash;
    console.log('currentPath', currentPath);
    if (currentPath.startsWith('#/assessment')) {
      navigate(`/assessment/${path}`);
    } else {
      navigate(`/${path}`);
    }
  };
  const handleDefaultNavigation = () => {
    const currentPath = window.location.hash;
    if (currentPath.startsWith('#/assessment')) {
      navigate('/assessment/home');
    } else {
      navigate('/');
    }
  };
  return (
    <Box sx={containerStyle}>
      <Box sx={leftContainerStyle}>
        <Typography variant='h2' sx={titleStyling}>
          {yellowOwlMessages.YELLOW_OWL}
        </Typography>
        <Typography variant='h6' sx={subtitle}>
          {yellowOwlMessages.YELLOW_OWL_DESCRIPTION}
        </Typography>
      </Box>
      <Box sx={boxStyle}>
        {(showLoginOptions || showSignUpOptions || forgotPasswordMode) && (
          <Button
            variant='text'
            onClick={() => {
              setShowLoginOptions(false);
              setShowSignUpOptions(false);
              setLoginMode(null);
              setAdditionalFields(false);
              setSetPasswordScreen(false);
              setForgotPasswordMode(false);
              handleDefaultNavigation();
            }}
            sx={{ position: 'absolute', top: 16, left: 16 }}
          >
            ← Back
          </Button>
        )}
        <Typography sx={headingStyle}>{getHeading()}</Typography>
        <Box sx={{ textAlign: 'center', marginTop: { xs: '10px', md: '30px' } }}>
          <img
            src={yowlLogoImage}
            alt='Yellow Owl Frame'
            style={{
              width: '100%',
              maxWidth: '300px',
              height: 'auto',
              display: 'block',
            }}
          />
        </Box>
        <Box sx={fixedContainerStyle}>
          {!showLoginOptions && !showSignUpOptions && !forgotPasswordMode && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, marginBottom: 2 }}>
              <Button
                variant='outlined'
                onClick={() => {
                  setShowLoginOptions(true);
                  handlePathClick('Login');
                  handleNavigation('login');
                }}
                sx={buttonStyle}
              >
                {yellowOwlMessages.LOGIN}
              </Button>
              <Button
                variant='outlined'
                onClick={() => {
                  setShowSignUpOptions(true);
                  handlePathClick('SignUp');
                  handleNavigation('signup');
                }}
                sx={buttonStyle}
              >
                {yellowOwlMessages.REGISTER}
              </Button>
            </Box>
          )}
          {showLoginOptions && !loginMode && pathClick === 'Login' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
              <Button variant='contained' onClick={() => setLoginMode('phoneNumber')} sx={buttonStyle}>
                {yellowOwlMessages.PHONE_NUMBER}
              </Button>
              <Button variant='contained' onClick={() => setLoginMode('email')} sx={buttonStyle}>
                {yellowOwlMessages.EMAIL}
              </Button>
              <Button variant='contained' onClick={() => setLoginMode('rollNumber')} sx={buttonStyle}>
                {yellowOwlMessages.ROLL_NUMBER}
              </Button>
            </Box>
          )}
          {showSignUpOptions && !loginMode && pathClick === 'SignUp' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
              <Button variant='contained' onClick={() => setLoginMode('phoneNumber')} sx={buttonStyle}>
                {yellowOwlMessages.PHONE_NUMBER}
              </Button>
              <Button variant='contained' onClick={() => setLoginMode('email')} sx={buttonStyle}>
                {yellowOwlMessages.EMAIL}
              </Button>
            </Box>
          )}
          {loginMode && !forgotPasswordMode && !additionalFields && (
            <>
              {pathClick === 'Login' && <Login />}
              {pathClick === 'SignUp' && <SignUp />}
            </>
          )}
          {forgotPasswordMode && <ForgotPassword />}
          {additionalFields && <Registration />}
        </Box>
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <Typography
            sx={{
              fontSize: '1rem',
              color: 'green',
              display: 'flex',
              justifyContent: 'center',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            <Link href='https://www.theyellowowl.com/privacy-policy' sx={{ color: 'brown' }}>
              {yellowOwlMessages.PRIVACY_POLICY}
            </Link>
            |
            <Link href='https://www.theyellowowl.com/about' sx={{ color: 'brown' }}>
              {yellowOwlMessages.ABOUT}
            </Link>
            |
            <Link href='https://www.theyellowowl.com/contact' sx={{ color: 'brown' }}>
              {yellowOwlMessages.CONTACT_US}
            </Link>
          </Typography>
        </Box>
        <Modal open={passwordResetSuccess} onClose={() => setPasswordResetSuccess(false)}>
          <Box sx={forgotPassWordSucessModalStyle}>
            <Typography sx={{ marginBottom: '20px' }}>{yellowOwlMessages.PASSWORD_RESET_SUCCESS}</Typography>
            <Button
              variant='contained'
              sx={{
                backgroundColor: '#4682B4',
                '&:hover': { backgroundColor: '#6A8FB5' },
                textTransform: 'none',
                borderRadius: '8px',
              }}
              onClick={() => {
                setPasswordResetSuccess(false);
                localStorage.clear();
                window.location.reload();
              }}
            >
              {yellowOwlMessages.OK}
            </Button>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default LoginPage;
