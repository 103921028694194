import React, { useState } from 'react';
import { Box, Button, TextField, InputAdornment, Select, MenuItem, IconButton, CircularProgress } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useLoginPageLogic } from '../../containers/signInAndSignUpContainer/signInAndSignUpContainer';
import { inputBoxStyle, buttonStyle } from './SignInAndRegisterPage';
import Layout from './Layout';
import { yellowOwlMessages } from '../../Constants';
import { Link } from 'react-router-dom';

const Login: React.FC = () => {
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState({ input: '', password: '' });
  const {
    loginMode,
    setLoginMode,
    inputValue,
    setInputValue,
    password,
    setPassword,
    showPassword,
    setShowPassword,
    selectedCountryCode,
    handleCountryCodeChange,
    handleLogin,
    isLoginLoading,
    passwordResetSuccess,
    setPasswordResetSuccess,
  } = useLoginPageLogic();

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handleLoginModeChange = (mode: 'phoneNumber' | 'email' | 'rollNumber') => {
    setLoginMode(mode);
    localStorage.setItem('loginMode', mode);
    handleNextStep();
  };

  const validateInput = () => {
    let inputError = '';
    let passwordError = '';

    if (loginMode === 'phoneNumber') {
      const phoneRegex = /^[0-9]{10}$/;
      if (!phoneRegex.test(inputValue)) {
        inputError = 'Please enter a valid 10-digit phone number.';
      }
    } else if (loginMode === 'email') {
      const emailRegex = /^[\w-.]+@[\w-]+\.[a-zA-Z]{2,}$/;
      if (!emailRegex.test(inputValue)) {
        inputError = 'Please enter a valid email address.';
      }
    } else if (loginMode === 'rollNumber' && !inputValue) {
      inputError = 'Roll number cannot be empty.';
    }

    if (loginMode !== 'rollNumber') {
      if (!password) {
        passwordError = 'Password cannot be empty.';
      } else if (password.length < 4 || password.length > 10) {
        passwordError = 'Password must be between 4 and 10 characters long.';
      }
    }

    setErrors({ input: inputError, password: passwordError });

    return !inputError && !passwordError;
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (validateInput()) {
      handleLogin();
    }
  };

  return (
    <Layout
      heading='Login'
      passwordResetSuccess={passwordResetSuccess}
      setPasswordResetSuccess={setPasswordResetSuccess}
    >
      <Box sx={{ ...inputBoxStyle, alignItems: 'center' }}>
        {step === 1 && (
          <>
            <Button
              variant='contained'
              onClick={() => handleLoginModeChange('phoneNumber')}
              sx={{ ...buttonStyle, marginBottom: '10px' }}
            >
              {yellowOwlMessages.PHONE_NUMBER}
            </Button>
            <Button
              variant='contained'
              onClick={() => handleLoginModeChange('email')}
              sx={{ ...buttonStyle, marginBottom: '10px' }}
            >
              {yellowOwlMessages.EMAIL}
            </Button>
            <Button variant='contained' onClick={() => handleLoginModeChange('rollNumber')} sx={buttonStyle}>
              {yellowOwlMessages.ROLL_NUMBER}
            </Button>
          </>
        )}
        {step === 2 && loginMode === 'phoneNumber' && (
          <>
            <Box sx={{ display: 'flex', gap: 1, marginBottom: '10px', alignItems: 'flex-start' }}>
              <Select
                value={selectedCountryCode}
                onChange={handleCountryCodeChange}
                sx={{ width: '30%', '& .MuiSelect-icon': { color: '#4682B4' } }}
              >
                <MenuItem value='+91'>+91 (India)</MenuItem>
                <MenuItem value='+1'>+1 (USA)</MenuItem>
                <MenuItem value='+44'>+44 (UK)</MenuItem>
              </Select>
              <TextField
                placeholder={yellowOwlMessages.ENTER_PHONE_NUMBER}
                value={inputValue}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setInputValue(value);
                  }
                }}
                fullWidth
                error={!!errors.input}
                helperText={errors.input}
                onKeyPress={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <PhoneIcon sx={{ color: '#4682B4' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </>
        )}
        {step === 2 && loginMode === 'email' && (
          <>
            <TextField
              placeholder={yellowOwlMessages.ENTER_EMAIL}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              fullWidth
              error={!!errors.input}
              helperText={errors.input}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon sx={{ color: '#4682B4' }} />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        {step === 2 && loginMode === 'rollNumber' && (
          <>
            <TextField
              placeholder={yellowOwlMessages.ENTER_ROLLNUMBER}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              fullWidth
              error={!!errors.input}
              helperText={errors.input}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
            />
            <Button variant='contained' onClick={handleSubmit} sx={buttonStyle} disabled={isLoginLoading}>
              {isLoginLoading ? <CircularProgress size={24} /> : yellowOwlMessages.LOGIN}
            </Button>
          </>
        )}
        {step === 2 && loginMode !== 'rollNumber' && (
          <>
            <TextField
              placeholder={yellowOwlMessages.ENTER_PASSWORD}
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              error={!!errors.password}
              helperText={errors.password}
              onKeyPress={handleKeyPress}
              sx={{ marginBottom: '10px' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowPassword(!showPassword)}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button variant='contained' onClick={handleSubmit} sx={buttonStyle} disabled={isLoginLoading}>
              {isLoginLoading ? <CircularProgress size={24} /> : yellowOwlMessages.LOGIN}
            </Button>
            <Link to='/forgot-password' style={{ textDecoration: 'none', color: '#4682B4', marginBottom: '10px' }}>
              {yellowOwlMessages.FORGOT_PASSWORD}
            </Link>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default Login;
