import { DefaultApi } from '@yellow-owl/client-sdk';
import axios, { InternalAxiosRequestConfig } from 'axios';
import { LSAuthDetails } from '../../utils/localStorageUtils';
import { auth } from '../../firebase-config';
import { TOKEN_KEY } from '../LocalStorageContext';

export const getUserAuthToken = (): string | undefined => {
  const user = localStorage.getItem(LSAuthDetails);
  if (user) {
    const userJson = JSON.parse(user);
    return userJson.token;
  }
};

export const refreshToken = async () => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      const newToken = await currentUser.getIdToken(true);
      const oldUserData = JSON.parse(JSON.stringify(localStorage.getItem(LSAuthDetails)));
      const updatedUserData = {
        phoneNumber: oldUserData.phoneNumber,
        token: newToken,
        uid: oldUserData.uid,
      };
      localStorage.setItem(LSAuthDetails, JSON.stringify(updatedUserData));
      return newToken;
    } else throw new Error();
  } catch (error) {
    console.error('Unable to fetch current user details');
  }
};

export const createYellowOwlClient = (baseUrl: string): DefaultApi => {
  const axiosInstance = axios.create();
  // auth  interceptor
  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig<any>): Promise<InternalAxiosRequestConfig<any>> => {
      const token = localStorage.getItem(TOKEN_KEY);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      } else {
        config.headers.Authorization = 'Bearer  ';
      }
      return config;
    }
  );

  // refresh token interceptor
  // axiosInstance.interceptors.response.use(
  //   (response) => response,
  //   async (error) => {
  //     const originalRequest = error.config;
  //     if (error.response.status === 401 && !originalRequest._retry) {
  //       if (error.response && error.response.data && error.response.data.message === 'Token-Expired') {
  //         originalRequest._retry = true;
  //         try {
  //           const access_token = await refreshToken(); // Call your refresh token function here
  //           const newRequest = { ...originalRequest };
  //           axiosInstance.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  //           newRequest.headers.Authorization = `Bearer ${access_token}`;
  //           return axiosInstance(newRequest);
  //         } catch (err) {
  //           console.log('Failed to refresh token:', err);
  //         }
  //       } else {
  //         auth.signOut();
  //         localStorage.clear();
  //       }
  //     }

  //     return Promise.reject(error);
  //   }
  // );

  return new DefaultApi(undefined, baseUrl, axiosInstance);
};
