import { Avatar, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../context/LocalStorageContext';
import profilePicture from '../../assets/images/profile-picture.png';

export const UserProfileComponent: React.FC = () => {
  const navigate = useNavigate();
  const handleUserProfile = () => {
    navigate('/user-profile');
  };
  const { state } = useLocalStorage();
  const avatar = state.avatar!;
  let userProfileIcon = profilePicture;

  {
    avatar ? (userProfileIcon = avatar) : (userProfileIcon = profilePicture);
  }

  return (
    <Box
      sx={{
        m: '5px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={handleUserProfile}
    >
      <Avatar src={userProfileIcon} alt={'profileIcon'} />
    </Box>
  );
};
