import { createContext, useContext, useState, ReactNode } from 'react';
import { getCodeForCountry } from '../utils/commonUtils';

// LocalStorage Keys
export const TOKEN_KEY = 'token';
export const TENANT_ID_KEY = 'tenantId';
export const USER_ID_KEY = 'userId';
export const PHONE_NUMBER_KEY = 'phoneNumber';
export const EMAIL_KEY = 'parentEmail';
export const USER_NAME_KEY = 'name';
export const CATEGORY_ID_KEY = 'categoryId';
export const COUNTRY_CODE_KEY = 'countryCode';
export const AGE_KEY = 'age';
export const PREV_TOKEN_KEY = 'prevToken';

// Add additional keys here
export const CITY_KEY = 'city';
export const COUNTRY_KEY = 'country';
export const STATE_KEY = 'state';
export const PARENT_PHONE_KEY = 'parentPhone';
export const DOB_KEY = 'dateOfBirth';
export const UID_KEY = 'uid';
export const SUBSCRIBER = 'isSubscriber';
export const USER_TYPE = 'userType';
export const LOGIN_MODE = 'loginMode';
export const AVATAR = 'avatar';
export const ROLL_NUMBER = 'rollNumber';

type LocalStorageState = {
  token: string | null;
  tenantId: string | null;
  userId: string | null;
  phoneNumber: string | null;
  categoryId: string | null;
  age: string | null;
  name: string | null;
  parentEmail: string | null;
  countryCode: string;
  parentPhone?: string | null;
  dateOfBirth?: string | null;
  city?: string | null;
  country?: string | null;
  state?: string | null;
  uid?: string | null;
  isSubscriber?: string | null;
  userType?: string | null;
  loginMode?: string | null;
  avatar?: string | null;
  prevToken?: string | null;
  rollNumber?: string | null;
};

type LocalStorageContextType = {
  state: LocalStorageState;
  updateLocalStorage: (key: keyof LocalStorageState, value: string | null) => void;
  getLocalStorage: (key: keyof LocalStorageState) => string | null;
};

// Create the context
const LocalStorageContext = createContext<LocalStorageContextType | undefined>(undefined);

// Provider component
export const LocalStorageProvider = ({ children }: { children: ReactNode }) => {
  const countryCodeNumber = localStorage.getItem(COUNTRY_CODE_KEY);

  const [state, setState] = useState<LocalStorageState>(() => ({
    token: localStorage.getItem(TOKEN_KEY),
    name: localStorage.getItem(USER_NAME_KEY),
    tenantId: localStorage.getItem(TENANT_ID_KEY),
    userId: localStorage.getItem(USER_ID_KEY),
    phoneNumber: localStorage.getItem(PHONE_NUMBER_KEY),
    parentEmail: localStorage.getItem(EMAIL_KEY),
    categoryId: localStorage.getItem(CATEGORY_ID_KEY),
    countryCode: countryCodeNumber ? getCodeForCountry(countryCodeNumber) : 'IN',
    age: localStorage.getItem(AGE_KEY),
    parentPhone: localStorage.getItem(PARENT_PHONE_KEY),
    dateOfBirth: localStorage.getItem(DOB_KEY),
    city: localStorage.getItem(CITY_KEY),
    country: localStorage.getItem(COUNTRY_KEY),
    state: localStorage.getItem(STATE_KEY),
    uid: localStorage.getItem(UID_KEY),
    isSubscriber: localStorage.getItem(SUBSCRIBER),
    userType: localStorage.getItem(USER_TYPE),
    loginMode: localStorage.getItem(LOGIN_MODE),
  }));

  // Function to update localStorage
  const updateLocalStorage = (key: keyof LocalStorageState, value: string | null) => {
    setState((prevState) => {
      const newState = { ...prevState, [key]: value };
      return newState;
    });
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  };

  // Function to get item from localStorage
  const getLocalStorage = (key: keyof LocalStorageState): string | null => {
    return localStorage.getItem(key);
  };

  return (
    <LocalStorageContext.Provider value={{ state, updateLocalStorage, getLocalStorage }}>
      {children}
    </LocalStorageContext.Provider>
  );
};

// Custom hook to use LocalStorageContext
export const useLocalStorage = (): LocalStorageContextType => {
  const context = useContext(LocalStorageContext);
  if (!context) {
    throw new Error('useLocalStorage must be used within a LocalStorageProvider');
  }
  return context;
};
