import React from 'react';
import { Box, Typography, Theme, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { Badges, BadgesResponse } from '@yellow-owl/client-sdk';

const badgeContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'center',
  width: '100px',
  height: '100px',
};

const badgeImageStyle: React.CSSProperties = {
  width: '44px',
  height: '52px',
};

const badgeInfoContainerStyle: SxProps<Theme> = {
  display: 'flex',
  margin: '10px',
  flexDirection: 'column',
  marginLeft: '10px',
  justifyContent: 'center',
};

const badgeTypeTextStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  fontWeight: 'bold',
};

const earnedBadgesTitleStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontWeight: 'bold',
};

const badgesScrollableContainerStyle: SxProps<Theme> = {
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  overflowY: 'hidden',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
};

const moreBadgesContainerStyle: SxProps<Theme> = {
  marginTop: '30px',
};

const BadgeComponent: React.FC<Badges> = ({ badgeType, badgeUrl, badgeDescription }) => {
  return (
    <Tooltip title={badgeDescription} arrow>
      <Box sx={badgeContainerStyle}>
        <Box>
          <img style={badgeImageStyle} src={badgeUrl} alt={badgeType} />
        </Box>
        <Box sx={badgeInfoContainerStyle}>
          <Typography sx={badgeTypeTextStyle}>
            {badgeType
              .split('_')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ')}
          </Typography>
        </Box>
      </Box>
    </Tooltip>
  );
};

export const Achievements: React.FC<BadgesResponse> = ({ availableBadges }) => {
  return (
    <>
      <Box sx={moreBadgesContainerStyle}>
        <Typography variant='h5' sx={earnedBadgesTitleStyle}>
          Next Up
        </Typography>
        <Box
          sx={{
            ...badgesScrollableContainerStyle,
            overflowY: 'auto',
            maxHeight: '300px',
          }}
        >
          {availableBadges?.map((badge) => (
            <BadgeComponent
              key={badge.badgeType}
              badgeType={badge.badgeType}
              badgeUrl={badge.badgeUrl}
              badgeDescription={badge.badgeDescription}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
