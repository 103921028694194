import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, IconButton, Stack, SxProps, Theme, Tooltip, styled } from '@mui/material';
import { ActivitySkillType, InputContent, QuestionFormat } from '@yellow-owl/client-sdk';
import { YellowOwlContext } from '../../../context/YellowOwlContext';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from '../confirmation-modal/confirmation-modal';
import PublishModal from '../publish-modal/PublishModal';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CancelIcon from '@mui/icons-material/Cancel';
import ShareIcon from '@mui/icons-material/Share';
import { useLocalStorage } from '../../../context/LocalStorageContext';
import { uploadToAzureBlob } from '../../../utils/azureBlob';
import { BlobServiceClient } from '@azure/storage-blob';
import uploadAnimation from '../../../assets/json/uploadAnimation.json';
import Lottie from 'react-lottie-player';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const imageUploaderBoxStyle: SxProps<Theme> = {
  margin: '4vh auto',
  cursor: 'pointer',
};

const fileUploaderStyle: SxProps<Theme> = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '30vw',
  height: '37vh',
  color: 'rgba(0, 0, 0, 0.6)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: '#fffff',
};

const fileUploadBtnBox: SxProps<Theme> = {
  padding: '10px',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '45px',
};

const deleteImageBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '10px',
  right: '10px',
  background: '#0000005c',
  height: '40px',
  width: '40px',
  borderRadius: '4px',
  color: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
};

const publishImageBtnStyle: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: '60px',
  right: '10px',
  background: '#0000005c',
  height: '40px',
  width: '40px',
  borderRadius: '4px',
  color: 'white',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
};

const cameraViewBox: SxProps<Theme> = {
  position: 'relative',
  width: '30vw',
  background: 'gray',
  overflow: 'hidden',
  img: {
    width: '100%',
    height: '100%',
  },
};

const imageCaptureBtns: SxProps<Theme> = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bottom: '15px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  gap: '10px',
  button: {
    height: '50px',
    width: '50px',
    margin: '0px 10px',
    background: '#fbec53',
    '&:hover': {
      background: '#ffe800',
    },
  },
};
// const defaultOptions = {
//   loop: true, // Loop the animation
//   autoplay: true, // Autoplay when the component is loaded
//   animationData: uploadAnimation, // The imported animation data
//   rendererSettings: {
//     preserveAspectRatio: 'xMidYMid slice', // Keeps aspect ratio
//   },
// };

const mimeType = 'video/webm; codecs="opus,vp8"';
function ThinkOutsideImageUploader(props: ThinkOutsideImageUploaderProps) {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const {
    questionFormat,
    userId,
    magazineId,
    magazineName,
    questionId,
    inputContent,
    questionFormatId,
    tenantId,
    categoryId,
    activityId,
    activityName,
    skill,
    url,
    globalInputState,
    onUpdateInputContents,
  } = props;
  const { state } = useLocalStorage();
  const [file, setFile] = useState<File>();
  const [fileUrl, setFileUrl] = useState<string>(url);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openPublishModal, setOpenPublishModal] = useState(false);
  const [showUploadImageBtn, setShowUploadImageBtn] = useState(false);
  const [openCamera, setOpenCamera] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const streamRef = useRef<MediaStream | null>(null);
  const [publishContent, setPublishContent] = useState({});
  const [isValid, setIsValid] = useState<boolean>(true);

  const [publishModaTitle, setPublishModaTitle] = useState('');
  const [publishModaContent, setPublishModaContent] = useState('');
  const grade = '3';
  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const saveImageResponse = async (url: string, showModal = false) => {
    try {
      setFileUrl(url);
      // let isMandatory = false;
      inputContent.forEach((inputElt: InputContent) => {
        inputElt.questionData.forEach((questionData) => {
          if (questionData.id === questionId) {
            questionData.questionFormat.forEach((questionFormat) => {
              if (questionFormat.format.id === questionFormatId) {
                // isMandatory = !!questionFormat?.format?.mandatory;
                questionFormat.format.response = url;
              }
            });
          }
        });
        if (questionFormat.format.mandatory) {
          setIsValid(url.trim().length > 0);
        } else {
          setIsValid(true);
        }
      });
      const result = await yellowOwlApi.addUserResponse({
        userId: +userId,
        magazineId: +magazineId,
        activityId: activityId,
        inputContent: inputContent,
        tenantId: tenantId,
        categoryId: categoryId,
        skill: skill,
      });
      if (result) {
        if (showModal) {
          handlePublishModalOpen(url);
        }
        onUpdateInputContents(inputContent);
      }
    } catch (e) {
      /**
       * Need to show notification accordingly
       */
    }
  };

  const uploadImageToBlob = async (file: File) => {
    if (file) {
      try {
        const URL = await uploadToAzureBlob(file);

        setFileUrl(URL);

        await saveImageResponse(URL, true);

        setShowUploadImageBtn(false);
      } catch (exception) {
        console.error('Error uploading image:', exception);
      }
    }
  };

  // TODO: this is not the right way to upload the file, we should be using signed token from the blob storage to do the same
  useEffect(() => {
    if (file) {
      uploadImageToBlob(file);
    }
  }, [file]);

  useEffect(() => {
    const validateAndSetImageUrl = async () => {
      if (url) {
        setShowUploadImageBtn(false);
      } else {
        setShowUploadImageBtn(true);
      }
    };
    validateAndSetImageUrl();
  }, []);

  useEffect(() => {
    if (fileUrl && globalInputState) {
      if (questionFormat.format.mandatory) {
        if (questionFormat.format.response && checkResponse(questionFormat.format.response)) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    }
  }, [fileUrl, globalInputState]);

  const handleDelete = () => {
    setOpenConfirmationModal(true);
  };

  const handleConfirmationModalClose = async (userResponse: boolean) => {
    setOpenConfirmationModal(false);
    if (userResponse) {
      try {
        const blobName = fileUrl?.split(`${process.env.REACT_APP_AZURE_CDN_BASE_URL}/`)[1];
        if (blobName) {
          const blobServiceClient = new BlobServiceClient(
            `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net?${process.env.REACT_APP_AZURE_SAS_TOKEN}`
          );
          const containerClient = blobServiceClient.getContainerClient(process.env.REACT_APP_AZURE_CONTAINER_NAME!);
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);
          await blockBlobClient.deleteIfExists();
        }
        setFileUrl('');
        setShowUploadImageBtn(true);
        await saveImageResponse('', false);
        await deleteImagePost();
      } catch (e) {
        console.error('Error deleting image from Azure Blob:', e);
        // Show appropriate notification for error
      }
    }
  };

  const handlePublishModalClose = (publishStatus: boolean, publishMessages: string) => {
    if (publishStatus) {
      // add notifications
      console.log(publishMessages);
    } else {
      // add notifications
    }
    setOpenPublishModal(false);
  };

  const createPublishContent = (artImageUrl: string, publishStatus: boolean) => {
    const dateTimeNow = new Date();
    return {
      tenantId: tenantId,
      userId: parseInt(userId),
      categoryId: categoryId,
      magazineId: parseInt(magazineId),
      activityId: parseInt(activityId),
      activityName: activityName,
      questionId: questionId,
      skill: skill.join(','),
      grade: grade,
      artImageURL: artImageUrl,
      publishStatus: publishStatus,
      postDateTime: dateTimeNow as any,
      rewardsPoints: 0,
      userName: state.name!,
      magazineName: magazineName ?? ' ',
      eventDevice: 'web',
    };
  };

  const handlePublishModalOpen = async (artImageUrl: string) => {
    const postId = `${tenantId}_${userId}_${categoryId}_${magazineId}_${activityId}_${questionId}`;
    if (postId) {
      try {
        const result = (await yellowOwlApi.getCommunityWallPostPublishStatus(
          tenantId.toString(),
          categoryId.toString(),
          userId,
          magazineId,
          activityId,
          questionId.toString()
        )) as CommunityWallPostPublishStatusResponse;
        if (result && result.data) {
          const isPostPublished = result.data.isPostPublished;
          const modalTitle = isPostPublished
            ? 'Stop Sharing on the Community Wall'
            : 'Do you want to share this on the shared community wall?';
          const modalContent = isPostPublished
            ? 'Are you sure you want to stop sharing this on the Community Wall? Your creative work will no longer be visible to your friends.'
            : 'Community wall is a shared space for your school where you can post your creative works for your friends to view.';
          const publishStatus = !isPostPublished;

          if (!isPostPublished && !artImageUrl) {
            return;
          }

          setPublishModaTitle(modalTitle);
          setPublishModaContent(modalContent);
          setPublishContent(createPublishContent(artImageUrl, publishStatus));
          setOpenPublishModal(true);
        }
      } catch (e) {
        // add notification
        console.log('can not get publish status');
      }
    }
  };

  const deleteImagePost = async () => {
    const deletePostContent = createPublishContent('', false);
    if (deletePostContent) {
      try {
        await yellowOwlApi.saveCommunityWallPost(deletePostContent);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const startRecording = async () => {
    setShowUploadImageBtn(false);
    setOpenCamera(true);
    const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    streamRef.current = stream;
    if (videoRef.current) {
      videoRef.current.srcObject = stream;
    }
    const recorder = new MediaRecorder(stream, { mimeType });
    recorder.start();
    setMediaRecorder(recorder);
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
  };

  const captureImage = async () => {
    if (videoRef.current) {
      const canvas = document.createElement('canvas');
      canvas.width = videoRef.current.videoWidth;
      canvas.height = videoRef.current.videoHeight;
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        const blob = await new Promise<Blob | null>((resolve) => canvas.toBlob(resolve, 'image/png'));
        if (blob) {
          stopRecording();
          const file = new File([blob], 'captured-image.png', { type: 'image/png' });
          uploadImageToBlob(file);
          setOpenCamera(false);
        } else {
          console.error('Error capturing image: Blob is null');
        }
      }
    }
  };

  const handleCancelCaptureImage = () => {
    stopRecording();
    setShowUploadImageBtn(true);
    setOpenCamera(false);
  };

  function isStringArray(value: any): value is string[] {
    return Array.isArray(value);
  }

  function checkResponse(response: string | string[]): boolean {
    if (typeof response === 'string') {
      return response.trim() !== '';
    } else if (isStringArray(response)) {
      return response.every((res) => res.trim() !== '');
    }
    return false;
  }

  useEffect(() => {
    if (globalInputState) {
      if (questionFormat.format.mandatory) {
        if (questionFormat.format.response && checkResponse(questionFormat.format.response)) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      } else {
        setIsValid(true);
      }
    }
  }, [globalInputState]);

  const fileUploadBackground = {
    width: '30vw',
    height: '37vh',
    background: `url(${fileUrl})`,
    backgroundSize: '100% 100%',
    border: isValid ? '4px solid #6AC6BF' : '4px solid red',
    borderRadius: '5px',
    position: 'relative',
  };

  const isPostPublishable = () => {
    return (questionFormat.format as any)?.isPublishable ?? false;
  };

  return (
    <Box sx={imageUploaderBoxStyle}>
      <Stack direction={'row'} justifyContent={'space-around'}>
        <Box sx={fileUploadBackground}>
          <Box sx={cameraViewBox} height={openCamera ? '37vh' : '0vh'}>
            <Box sx={{ height: '37vh', width: '30vw' }}>
              <video ref={videoRef} autoPlay muted width={'100%'} height={'100%'}></video>
            </Box>
            <Box sx={imageCaptureBtns}>
              <IconButton onClick={captureImage}>
                <CameraEnhanceIcon sx={{ color: '#000' }} />
              </IconButton>
              <IconButton onClick={handleCancelCaptureImage}>
                <CancelIcon sx={{ color: '#000' }} />
              </IconButton>
            </Box>
          </Box>

          {showUploadImageBtn && (
            <Box sx={fileUploaderStyle}>
              <Box sx={{ marginBottom: '-70px', display: 'flex', justifyContent: 'center' }}>
                <Lottie loop animationData={uploadAnimation} play style={{ width: 450, height: 250 }} />
              </Box>

              <Box sx={fileUploadBtnBox}>
                <Button
                  component='label'
                  role={undefined}
                  variant='contained'
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  fullWidth
                  sx={{
                    margin: '10px',
                    width: '177px',
                    backgroundColor: '#6AC6BF',
                    '&:hover': {
                      backgroundColor: '#6AC6BF',
                    },
                  }}
                >
                  Upload Photo
                  <VisuallyHiddenInput type='file' accept='image/*' onChange={handleFileChange} />
                </Button>
                <Button
                  component='label'
                  variant='contained'
                  tabIndex={-1}
                  onClick={startRecording}
                  startIcon={<CameraAltIcon />}
                  fullWidth
                  sx={{
                    margin: '10px',
                    width: '177px',
                    backgroundColor: '#6AC6BF',
                    '&:hover': {
                      backgroundColor: '#6AC6BF',
                    },
                  }}
                >
                  Take Photo
                </Button>
              </Box>
            </Box>
          )}
          {fileUrl && (
            <>
              <Box sx={deleteImageBtnStyle}>
                <Tooltip title={'Delete'} placement='top'>
                  <IconButton onClick={handleDelete}>
                    <DeleteIcon sx={{ color: '#fff' }} />
                  </IconButton>
                </Tooltip>
              </Box>
              {isPostPublishable() && (
                <Box sx={publishImageBtnStyle}>
                  <Tooltip title={'Publish'} placement='top'>
                    <IconButton onClick={() => handlePublishModalOpen(fileUrl)}>
                      <ShareIcon sx={{ color: '#fff' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </Box>
      </Stack>
      <ConfirmationModal
        title='Delete Image'
        content='Are you sure you want to delete this image?'
        open={openConfirmationModal}
        onClose={handleConfirmationModalClose}
      />
      <PublishModal
        title={publishModaTitle}
        content={publishModaContent}
        open={openPublishModal}
        publishContent={publishContent}
        onClose={handlePublishModalClose}
      />
    </Box>
  );
}

interface ThinkOutsideImageUploaderProps {
  questionFormat: QuestionFormat;
  image: string;
  inputContent: Array<InputContent>;
  questionId: number;
  questionFormatId: number;
  userId: string;
  magazineId: string;
  magazineName: string;
  tenantId: number;
  categoryId: number;
  activityId: string;
  activityName: string;
  skill: Array<ActivitySkillType>;
  url: string;
  globalInputState: boolean;
  onUpdateInputContents: (input: Array<InputContent>) => boolean;
}
interface CommunityWallPostPublishStatusResponse {
  data: {
    isPostPublished: boolean;
  };
}

export default ThinkOutsideImageUploader;
