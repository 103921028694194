import { Box, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  AGE_KEY,
  CATEGORY_ID_KEY,
  COUNTRY_CODE_KEY,
  EMAIL_KEY,
  PHONE_NUMBER_KEY,
  TENANT_ID_KEY,
  TOKEN_KEY,
  USER_ID_KEY,
  USER_NAME_KEY,
  useLocalStorage,
} from '../../context/LocalStorageContext';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import NavigationConfirmationModal from './confirmation-modal/NavigationConfirmationModal';
import { useUserLoggedOutEvents } from '../../services/userLoggedInEvent';

// TODO: refactor with proper header in all the pages, also get the UI from designer
export const LogoutComponent: React.FC = () => {
  const { updateLocalStorage } = useLocalStorage();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { sendLoggedOutEvent } = useUserLoggedOutEvents();

  const handleConfirmationModalClose = async (confirm: boolean) => {
    setOpenConfirmationModal(false);
    if (confirm) {
      await sendLoggedOutEvent();
      updateLocalStorage(TOKEN_KEY, null);
      updateLocalStorage(TENANT_ID_KEY, null);
      updateLocalStorage(USER_ID_KEY, null);
      updateLocalStorage(PHONE_NUMBER_KEY, null);
      updateLocalStorage(EMAIL_KEY, null);
      updateLocalStorage(USER_NAME_KEY, null);
      updateLocalStorage(CATEGORY_ID_KEY, null);
      updateLocalStorage(COUNTRY_CODE_KEY, null);
      updateLocalStorage(AGE_KEY, null);
      localStorage.clear();
    }
  };

  const handleLogoutClick = () => {
    setOpenConfirmationModal(true);
  };

  const { state } = useLocalStorage();
  const [searchParams] = useSearchParams();
  const magazineId = searchParams.get('id') || '0';
  const activityId = searchParams.get('actid') || '0';
  const userId = state.userId!;
  const tenantId = state.tenantId!;
  const categoryId = state.categoryId!;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Button
        sx={{
          marginLeft: 1,
          borderRadius: '24px',
          padding: '10px 20px',
          backgroundColor: '#46B8B0',
          color: 'white',
          border: 'none',
          '&:hover': {
            backgroundColor: '#46B8B0',
            color: 'white',
          },
        }}
        onClick={handleLogoutClick}
      >
        Logout
        <LogoutIcon sx={{ ml: 1 }} />
      </Button>
      <NavigationConfirmationModal
        title=''
        content='Are you sure you want to logout?'
        open={openConfirmationModal}
        onClose={handleConfirmationModalClose}
        userId={userId}
        magazineId={magazineId}
        activityId={activityId}
        tenantId={+tenantId}
        categoryId={+categoryId}
      />
    </Box>
  );
};

export default LogoutComponent;
