import { Navigate, Route, Routes } from 'react-router-dom';
import { GetOTPPageContainer } from '../containers/walkthrough/GetOTPPageContainer';
import { ValidateOTPPageContainer } from '../containers/walkthrough/ValidateOTPPageContainer';
import { GuardedRoute } from './routeGuards/GuardedRoute';
import { ExploreMagazinesContainer } from '../containers/walkthrough/ExploreMagazinesContainer';
import { CoverPageContainer } from '../containers/magazine/CoverPageContainer';
import { IndexPageContainer } from '../containers/magazine/IndexPageContainer';
import { ActivityPageContainer } from '../containers/magazine/ActivityPageContainer';
import { NoteToParent } from '../components/walkthrough/NoteToParent';
import { PrivacyPolicyPage } from '../components/walkthrough/PrivacyPolicyPage';
import { ContactUsPage } from '../components/walkthrough/ContactUsPage';
import { AuthenticatedRoute } from '../containers/common/AuthenticatedRoute';
import { useLocalStorage } from '../context/LocalStorageContext';
import { CommunityWallContainer } from '../containers/community-wall/CommunityWallContainer';
import { TeacherModule } from '../containers/teachers-module/TeacherModuleContainer';
import { UserProfileContainer } from '../containers/user-profile/UserProfileContainer';
import { LeaderboardContainer } from '../containers/leaderboard/LeaderboardContainer';
import LoginPage from '../components/SignInAndSignUp/SignInAndRegisterPage';
import Login from '../components/SignInAndSignUp/Login';
import { Registration } from '../components/SignInAndSignUp/registration';
import SignUp from '../components/SignInAndSignUp/signUp';
import ForgotPassword from '../components/SignInAndSignUp/forgotPassword';
import AssessmentQuestionsContainer from '../containers/assessments/AssessmentQuestionContainer';
import AssessmentsLibraryContainer from '../containers/assessments/AssessmentLibraryContainer';
import AssessmentHomePageContainer from '../containers/assessments/AssessmentHomeContainer';
import { UserDetailGuard } from './routeGuards/UserDetailGuards';
export type AppRouteProps = {
  isAuthenticated: boolean;
};
export const LANDING_PAGE = '/';
export const ASSESSMENT_LANDING_PAGE = '/assessment/home';
export const ASSESSMENT_SIGIN_AND_SIGNUP = '/assessment/signin-signup';
export const SIGN_IN_PAGE = '/otp-page';
export const VALIDATE_OTP_PAGE = '/validate-otp-page';
export const ADD_NAME_PAGE = '/add-name';
export const ADD_AGE_PAGE = '/add-age';
export const ADD_EMAIL_PAGE = 'add-email';
export const ADD_LOCATION_PAGE = '/add-location';
export const EXPLORE_MAGAZINES = '/magazines';
export const COVER_PAGE = '/magazine/cover';
export const INDEX_PAGE = '/magazine/index';
export const STORY_TIME_PAGE = '/magazine/story-time';
export const THINK_OUTSIDE_PAGE = '/magazine/think-outside';
export const SPIN_A_STORY_PAGE = 'magazine/spin-a-story';
export const DEEP_DIVE = '/magazine/deep-dive';
export const ALL_EARS = 'magazine/all-ears';
export const ACTIVITY = 'magazine/activity';
export const NOTE_TO_PARENT = '/note-to-parent';
export const PRIVACY_POLICY = '/privacy-policy';
export const CONTACT_US = '/contact-us';
export const ERROR = '/try-later';
export const COMMUNITY_WALL = '/community-wall';
export const LEADER_BOARD_PAGE = '/leaderboard/:tenantId';
export const STUDENT_RESPONSE = '/student-response';
export const USER_PROFILE = '/user-profile';
export const FORGOT_PASSWORD = '/forgot-password';
export const ASSESSMENT_FORGOT_PASSWORD = '/forgot-password';
export const SIGN_UP = '/signup';
export const LOGIN = '/login';
export const ASSESSMENT_LOGIN_PAGE = '/assessment/login';
export const ASSESSMENT_SIGN_UP = '/assessment/signup';
export const ASSESSMENT_LIBRARY = '/assessment/library';
export const REGISTRATION = '/registration';
export const ASSESSMENT_REGISTRATION = '/assessment/registration';
export const ASSESSMENT_QUESTION = '/assessment-questions/:assessmentId';
export const AppRoutes: React.FC = () => {
  const { state } = useLocalStorage();
  const isAuthenticated = Boolean(state.token && state.token !== '');
  return (
    <Routes>
      {/* Unauthenticated Routes */}

      <Route path={ASSESSMENT_LANDING_PAGE} element={<AssessmentHomePageContainer />} />
      <Route path={ASSESSMENT_SIGIN_AND_SIGNUP} element={<LoginPage />} />
      <Route path={ASSESSMENT_LOGIN_PAGE} element={<Login />} />
      <Route path={ASSESSMENT_SIGN_UP} element={<SignUp />} />
      <Route path={ASSESSMENT_FORGOT_PASSWORD} element={<ForgotPassword />} />

      {/* Guarded Assessment Routes */}
      <Route
        element={
          <GuardedRoute
            isRouteAccessible={isAuthenticated}
            redirectRoute={ASSESSMENT_LANDING_PAGE}
            allowAssessmentRoutes
          />
        }
      >
        <Route path={ASSESSMENT_REGISTRATION} element={<Registration />} />
        <Route
          path={ASSESSMENT_LIBRARY}
          element={
            <UserDetailGuard>
              <AssessmentsLibraryContainer />
            </UserDetailGuard>
          }
        />
        <Route path={ASSESSMENT_QUESTION} element={<AssessmentQuestionsContainer />} />
      </Route>
      {/* Non-Assessment Routes */}
      <Route element={<GuardedRoute isRouteAccessible={!isAuthenticated} redirectRoute={EXPLORE_MAGAZINES} />}>
        <Route path={LANDING_PAGE} element={<LoginPage />} />
        <Route path={NOTE_TO_PARENT} element={<NoteToParent />} />
        <Route path={PRIVACY_POLICY} element={<PrivacyPolicyPage />} />
        <Route path={CONTACT_US} element={<ContactUsPage />} />
        <Route path={SIGN_IN_PAGE} element={<GetOTPPageContainer />} />
        <Route path={VALIDATE_OTP_PAGE} element={<ValidateOTPPageContainer />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={SIGN_UP} element={<SignUp />} />
        <Route path={FORGOT_PASSWORD} element={<ForgotPassword />} />
      </Route>
      {/* Guarded Non-Assessment Routes */}
      <Route element={<GuardedRoute isRouteAccessible={isAuthenticated} redirectRoute={LANDING_PAGE} />}>
        <Route
          path={EXPLORE_MAGAZINES}
          element={
            <UserDetailGuard>
              <ExploreMagazinesContainer />
            </UserDetailGuard>
          }
        />
        <Route path={COVER_PAGE} element={<AuthenticatedRoute component={<CoverPageContainer />} />} />
        <Route path={INDEX_PAGE} element={<AuthenticatedRoute component={<IndexPageContainer />} />} />
        <Route path={ACTIVITY} element={<AuthenticatedRoute component={<ActivityPageContainer />} />} />
        <Route path={USER_PROFILE} element={<AuthenticatedRoute component={<UserProfileContainer />} />} />
        <Route path={LEADER_BOARD_PAGE} element={<AuthenticatedRoute component={<LeaderboardContainer />} />} />
      </Route>
      {/* Fallback Routes */}
      <Route path='*' element={<Navigate to={EXPLORE_MAGAZINES} replace />} />
      <Route path={ERROR} element={<h2>Please try again sometimes later</h2>} />
      <Route path={COMMUNITY_WALL} element={<CommunityWallContainer isProfilePage={false} />} />
      <Route path={STUDENT_RESPONSE} element={<TeacherModule />} />
    </Routes>
  );
};
