import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, SxProps, Theme } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';

const noResultsContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '20px',
};

const iconStyle: SxProps<Theme> = {
  fontSize: '100px',
  color: '#6AC6BF',
  marginBottom: '20px',
};

const textStyle: SxProps<Theme> = {
  color: '#6AC6BF',
  fontWeight: 'bold',
  fontFamily: 'CormorantGaramondRegular',
};

const NoResultFound: React.FC = () => {
  return (
    <Box sx={noResultsContainerStyle}>
      <SentimentVeryDissatisfiedIcon sx={iconStyle} />
      <Typography variant='h5' component='div' sx={textStyle}>
        Oops! No Results Found
      </Typography>
      <Typography variant='body1' component='div' sx={textStyle}>
        Try searching for something else.
      </Typography>
    </Box>
  );
};

export default NoResultFound;
