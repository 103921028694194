import React, { useContext, useEffect, useState } from 'react';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { CommunityWallPage } from '../../components/community-wall/CommunityWallPage';
import { useAsyncEffect } from '../../hooks/use-async';

interface UpdateCommunityWallPostViewsParams {
  tenantId: string;
  categoryId: string;
  userId: string;
  magazineId: string;
  activityId: string;
  questionId: string;
  views: number;
}

interface CommunityWallContainerProps {
  isProfilePage: boolean;
}

export const CommunityWallContainer: React.FC<CommunityWallContainerProps> = ({ isProfilePage }) => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const [communityWallContent, setCommunityWallContent] = useState<any>();
  const [skill, setSkill] = useState<string>('no-filter');
  const [grade, setGrade] = useState<string>('no-filter');

  const [communityWallState] = useAsyncEffect<any>({
    fn: async () => {
      const result = await yellowOwlApi.getCommunityWallPostContents({ skill: skill, grade: grade });
      return result.data;
    },
    dependencies: [skill, grade],
  });

  useEffect(() => {
    if (communityWallState.result != undefined) {
      setCommunityWallContent(communityWallState.result!);
    }
  }, [communityWallState]);

  const setSkillFilter = (newSkill: string) => {
    setSkill(newSkill);
  };

  const setGradeFilter = (newGrade: string) => {
    setGrade(newGrade);
  };

  const updateViewCount = async (
    tenantId: string,
    categoryId: string,
    userId: string,
    magazineId: string,
    activityId: string,
    questionId: string,
    views: number
  ) => {
    try {
      // this local storage logic is not final
      // it should be handled by api in upcoming iterations
      const viewedPost = localStorage.getItem('viewedPost');
      let viewedPostList: string[] = [];
      if (viewedPost) {
        viewedPostList = JSON.parse(viewedPost);
      }

      const postId = `${tenantId}_${categoryId}_${userId}_${magazineId}_${activityId}_${questionId}`;

      if (!viewedPostList.includes(postId)) {
        views++;
        const params: UpdateCommunityWallPostViewsParams = {
          tenantId,
          categoryId,
          userId,
          magazineId,
          activityId,
          questionId,
          views,
        };
        const result = await yellowOwlApi.updateCommunityWallPostViews(params);
        if (result) {
          viewedPostList.push(postId);
          localStorage.setItem('viewedPost', JSON.stringify(viewedPostList));
        }
      }
    } catch (error) {
      console.error('Failed to update view count', error);
    }
  };

  return (
    <>
      {communityWallContent && (
        <CommunityWallPage
          communityWallContent={communityWallContent}
          setSkillFilter={setSkillFilter}
          setGradeFilter={setGradeFilter}
          updateViewCount={updateViewCount}
          isProfilePage={isProfilePage}
        />
      )}
    </>
  );
};
