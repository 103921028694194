import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import kid1 from '../../assets/images/kid1.png';
import kid2 from '../../assets/images/kid2.png';
import yowlLogo from '../../assets/images/YowlLogo.png';
import assessmentBg from '../../assets/images/assessmentBg.svg';
import { SxProps, Theme } from '@mui/system';
import { theme } from '../../theme';

export const containerStyles: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundImage: `url(${assessmentBg})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  color: 'black',
  textAlign: 'center',
  fontFamily: "'Livvic', sans-serif",
};

export const logoStyles: SxProps<Theme> = {
  marginBottom: 3,
  marginTop: '-100px',
  '& img': {
    width: '150px',
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
  },
};

export const headingStyles: SxProps<Theme> = {
  marginBottom: 3,
  fontSize: '36px',
  color: '#46b8b0',
  fontFamily: "'Livvic', sans-serif",
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '24px',
  },
};

export const skillsFlipStyles: SxProps<Theme> = {
  fontSize: '30px',
  color: '#46b8b0',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
  },
};

export const buttonStyles: SxProps<Theme> = {
  paddingX: 4,
  paddingY: 2,
  fontSize: '20px',
  backgroundColor: '#0056b3',
  color: 'white',
  borderRadius: '5px',
  transition: 'background-color 0.3s ease',
  fontFamily: "'Livvic', sans-serif",
  '&:hover': {
    backgroundColor: '#003d80',
  },
  [theme.breakpoints.down('sm')]: {
    paddingX: 2,
    paddingY: 1,
    fontSize: '16px',
  },
};

export const imageContainerStyles: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& img': {
    width: '300px',
    maxWidth: '400px',
    height: 'auto',
    transition: 'width 0.3s ease',
    [theme.breakpoints.down('sm')]: {
      width: '100px',
    },
    [theme.breakpoints.down('md')]: {
      width: '150px',
    },
  },
  '.left-placeholder': {
    position: 'fixed',
    left: '20px',
    bottom: '20px',
    [theme.breakpoints.down('sm')]: {
      left: '5px',
    },
    [theme.breakpoints.down('md')]: {
      left: '10px',
    },
  },
  '.right-placeholder': {
    position: 'fixed',
    right: '20px',
    bottom: '20px',
    [theme.breakpoints.down('sm')]: {
      right: '5px',
    },
    [theme.breakpoints.down('md')]: {
      right: '10px',
    },
  },
};

interface AssessmentHomePageComponentProps {
  displayedText: string;
  onStartClick: () => void;
}

const AssessmentHomePageComponent: React.FC<AssessmentHomePageComponentProps> = ({ displayedText, onStartClick }) => {
  return (
    <Box sx={containerStyles}>
      <Box sx={logoStyles}>
        <img src={yowlLogo} alt='Yowl Logo' />
      </Box>
      <Typography sx={headingStyles}>Lets test your 21st century skills</Typography>
      <Box sx={{ height: '40px', marginBottom: 4 }}>
        <Typography sx={skillsFlipStyles}>{displayedText}</Typography>
      </Box>
      <Box sx={imageContainerStyles}>
        <img src={kid2} alt='Left Placeholder' className='left-placeholder' />
        <Button sx={buttonStyles} onClick={onStartClick}>
          Start Now
        </Button>
        <img src={kid1} alt='Right Placeholder' className='right-placeholder' />
      </Box>
    </Box>
  );
};

export default AssessmentHomePageComponent;
