import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../../context/LocalStorageContext';
import AssessmentHomePageComponent from '../../components/assesments/AssessmentHomePage';

const skills = ['Creativity', 'Communication', 'Socio-emotional', 'Critical Thinking', 'Collaboration'];

const AssessmentHomePageContainer: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useLocalStorage();
  const isAuthenticated = Boolean(state.token);

  const [currentSkillIndex, setCurrentSkillIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const typingSpeed = 100;
  const deletingSpeed = 50;
  const pauseTime = 2000;

  useEffect(() => {
    const handleTyping = () => {
      const fullText = skills[currentSkillIndex];

      if (!isDeleting) {
        setDisplayedText((prev) => fullText.substring(0, prev.length + 1));
        if (displayedText === fullText) {
          setTimeout(() => setIsDeleting(true), pauseTime);
        }
      } else {
        setDisplayedText((prev) => fullText.substring(0, prev.length - 1));
        if (displayedText === '') {
          setIsDeleting(false);
          setCurrentSkillIndex((prevIndex) => (prevIndex + 1) % skills.length);
        }
      }
    };

    const interval = setTimeout(handleTyping, isDeleting ? deletingSpeed : typingSpeed);
    return () => clearTimeout(interval);
  }, [displayedText, isDeleting, currentSkillIndex]);

  const handleStartClick = () => {
    localStorage.setItem('isAssessment', 'true');
    navigate(isAuthenticated ? '/assessment/library' : '/assessment/signin-signup');
  };

  return <AssessmentHomePageComponent displayedText={displayedText} onStartClick={handleStartClick} />;
};

export default AssessmentHomePageContainer;
