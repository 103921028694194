/* eslint-disable */
import { useState, useContext, useEffect } from 'react';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import {
  AGE_KEY,
  CATEGORY_ID_KEY,
  COUNTRY_CODE_KEY,
  DOB_KEY,
  EMAIL_KEY,
  LOGIN_MODE,
  PHONE_NUMBER_KEY,
  ROLL_NUMBER,
  TENANT_ID_KEY,
  TOKEN_KEY,
  useLocalStorage,
  USER_ID_KEY,
  USER_NAME_KEY,
} from '../../context/LocalStorageContext';
import dayjs from 'dayjs';
import { SelectChangeEvent } from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAsync } from '../../hooks/use-async';
import { yellowOwlMessages } from '../../Constants';

export const useLoginPageLogic = () => {
  const [loginMode, setLoginMode] = useState<'phoneNumber' | 'email' | 'rollNumber' | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginOptions, setShowLoginOptions] = useState(false);
  const [showSignUpOptions, setShowSignUpOptions] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('+91');
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [additionalFields, setAdditionalFields] = useState(false);
  const [setPasswordScreen, setSetPasswordScreen] = useState(false);
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  const [resendOtpTimer, setResendOtpTimer] = useState(0);
  const yellowOwlApi = useContext(YellowOwlContext);
  const { updateLocalStorage } = useLocalStorage();
  const [pathClick, setPathClick] = useState<'Login' | 'SignUp' | null>(null);
  const [userDetail, setUserDetail] = useState<Record<string, any> | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendOtpTimer > 0) {
      timer = setTimeout(() => setResendOtpTimer(resendOtpTimer - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendOtpTimer]);

  const handleCountryCodeChange = (event: SelectChangeEvent<string>) => {
    setSelectedCountryCode(event.target.value as string);
  };

  const handlePathClick = (path: 'Login' | 'SignUp') => {
    setPathClick(path);
  };

  const isValidAge = (dob: Date): boolean => {
    const today = dayjs();
    const birthDate = dayjs(dob);
    const age = today.diff(birthDate, 'year');

    return age >= 6 && age <= 12;
  };
  const [{ isLoading: isOtpLoading }, checkAndOtp] = useAsync({
    fn: async () => {
      try {
        const fullPhoneNumber = `${selectedCountryCode}${inputValue}`;
        const response = await yellowOwlApi?.checkIfVerifiedUser(fullPhoneNumber);
        const isVerified = response?.data?.isVerifiedUser;
        if (!isVerified) {
          const otpResponse = await yellowOwlApi?.generateOTPForPhoneNumber({ phoneNumber: fullPhoneNumber });
          if (otpResponse) {
            toast.success(yellowOwlMessages.OTP_SENT);
            setOtpSent(true);
            setResendOtpTimer(60);
          } else {
            throw new Error(yellowOwlMessages.OTP_SEND_FAIL);
          }
        } else {
          toast.error(yellowOwlMessages.USER_ALREADY_REGISTERED);
          navigate('/');
        }
      } catch (error) {
        toast.error(yellowOwlMessages.OTP_SEND_FAIL);
      }
    },
  });

  const [{ isLoading: isOtpEmailLoading }, checkAndOtpEmail] = useAsync({
    fn: async () => {
      try {
        const response = await yellowOwlApi?.checkIfVerifiedUser('123', inputValue);
        const isVerified = response?.data?.isVerifiedUser;
        if (!isVerified) {
          const otpResponse = await yellowOwlApi?.generateOTPForPhoneNumber({ email: inputValue });
          if (otpResponse) {
            toast.success(yellowOwlMessages.OTP_SENT);
            setOtpSent(true);
            setResendOtpTimer(60);
          } else {
            throw new Error(yellowOwlMessages.OTP_SEND_FAIL);
          }
        } else {
          toast.error(yellowOwlMessages.USER_ALREADY_REGISTERED_EMAIL);
          navigate('/');
        }
      } catch (error) {
        toast.error(yellowOwlMessages.OTP_SEND_FAIL);
      }
    },
  });

  const handleSendOtp = () => {
    if (loginMode === 'phoneNumber') {
      checkAndOtp();
    } else if (loginMode === 'email') {
      checkAndOtpEmail();
    }
  };

  const [{ isLoading: isResendOtpLoading }, handleResendOtp] = useAsync({
    fn: async () => {
      if (resendOtpTimer > 0) return;
      try {
        if (loginMode === 'phoneNumber') {
          const fullPhoneNumber = `${selectedCountryCode}${inputValue}`;
          await yellowOwlApi?.resendOTP({ phoneNumber: fullPhoneNumber });
        } else if (loginMode === 'email') {
          await yellowOwlApi?.resendOTP({ email: inputValue });
        }
        setResendOtpTimer(60);
      } catch (error) {
        toast.error(yellowOwlMessages.OTP_SEND_FAIL);
      }
    },
  });

  const [{ isLoading: isVerifyOtpLoading }, handleVerifyOtp] = useAsync({
    fn: async () => {
      const fullPhoneNumber = `${selectedCountryCode}${inputValue}`;
      try {
        const verifyResponse = await yellowOwlApi?.verifyOtp({ phoneNumber: fullPhoneNumber, otp: otp });
        if (verifyResponse?.data?.token) {
          localStorage.setItem('token', verifyResponse.data.token);
          toast.success(yellowOwlMessages.OTP_VERIFIED);
          setSetPasswordScreen(true);
        } else {
          toast.error(yellowOwlMessages.INVALID_OTP);
        }
      } catch (error) {
        toast.error(yellowOwlMessages.INVALID_OTP);
      }
    },
  });

  const [{ isLoading: isVerifyOtpEmailLoading }, handleVerifyOtpEmail] = useAsync({
    fn: async () => {
      const verifyResponse = await yellowOwlApi?.verifyOtp({ email: inputValue, otp: otp });
      if (verifyResponse?.data?.token) {
        localStorage.setItem('token', verifyResponse.data.token);
        // updateLocalStorage(PREV_TOKEN_KEY, verifyResponse.data.token);
        setSetPasswordScreen(true);
      } else {
        toast.error(yellowOwlMessages.INVALID_OTP);
      }
    },
  });

  const storeUserDetails = (
    userDetails: any,
    fullPhoneNumber: string | null,
    email: string | null,
    selectedCountryCode: string
  ) => {
    updateLocalStorage(USER_ID_KEY, userDetails.userStrapiId ?? null);
    updateLocalStorage(USER_NAME_KEY, userDetails.userName);
    updateLocalStorage(DOB_KEY, userDetails.dateOfBirth ?? null);
    updateLocalStorage(EMAIL_KEY, userDetails.parentMailId ?? email);
    updateLocalStorage(PHONE_NUMBER_KEY, fullPhoneNumber);
    updateLocalStorage(COUNTRY_CODE_KEY, selectedCountryCode);
    updateLocalStorage(CATEGORY_ID_KEY, userDetails.commonCategoryId ?? null);
    updateLocalStorage(TENANT_ID_KEY, userDetails.tenantId ?? null);
    updateLocalStorage(LOGIN_MODE, loginMode);
    updateLocalStorage(AGE_KEY, userDetails.userAge.toString());
  };

  const [{ isLoading: isSetPasswordLoading }, handleSetPassword] = useAsync({
    fn: async () => {
      if (password !== confirmPassword) {
        toast.error(yellowOwlMessages.PASSWORDS_DO_NOT_MATCH);
        return;
      }

      try {
        let response;
        if (loginMode === 'phoneNumber') {
          const fullPhoneNumber = `${selectedCountryCode}${inputValue}`;
          const response = await yellowOwlApi?.setPassword({ phoneNumber: fullPhoneNumber, password: password });
          if (response) {
            if (!forgotPasswordMode) {
              const response = await yellowOwlApi?.getUserDetails(fullPhoneNumber);
              const userDetails = response?.data;
              if (userDetails) {
                storeUserDetails(userDetails, fullPhoneNumber, null, selectedCountryCode);
              }
              const token = localStorage.getItem('token');
              updateLocalStorage(TOKEN_KEY, token);
              if (localStorage.getItem('isAssessment') === 'true') {
                navigate('/assessment/registration');
              }
            } else {
              setPasswordResetSuccess(true);
            }
            setSetPasswordScreen(false);
          } else {
            toast.error(yellowOwlMessages.FAILED_TO_SET_PASSWORD);
          }
        } else if (loginMode === 'email') {
          setLoginMode('email');
          const response = await yellowOwlApi?.setPassword({ email: inputValue, password: password });
          if (response) {
            if (!forgotPasswordMode) {
              const response = await yellowOwlApi?.getUserDetails(undefined, inputValue);
              const userDetails = response?.data;
              if (userDetails) {
                storeUserDetails(userDetails, null, inputValue, selectedCountryCode);
              }
              localStorage.setItem('email', inputValue);
              updateLocalStorage(LOGIN_MODE, 'email');
              const token = localStorage.getItem('token');
              updateLocalStorage(TOKEN_KEY, token);
              if (localStorage.getItem('isAssessment') === 'true') {
                navigate('/assessment/registration');
              }
            } else {
              setPasswordResetSuccess(true);
            }
            setSetPasswordScreen(false);
          } else {
            toast.error(yellowOwlMessages.FAILED_TO_SET_PASSWORD);
          }
        }
      } catch (error) {
        toast.error(yellowOwlMessages.FAILED_TO_SET_PASSWORD);
      }
    },
  });

  const [{ isLoading: isForgotPasswordVerifyLoading }, handleForgotPasswordVerify] = useAsync({
    fn: async () => {
      if (loginMode === 'phoneNumber') {
        const fullPhoneNumber = `${selectedCountryCode}${inputValue}`;
        const response = await yellowOwlApi?.checkIfVerifiedUser(fullPhoneNumber);
        const isVerified = response?.data?.isVerifiedUser;

        if (isVerified) {
          const otpResponse = await yellowOwlApi?.generateOTPForPhoneNumber({ phoneNumber: fullPhoneNumber });
          if (otpResponse) {
            toast.success(yellowOwlMessages.OTP_SENT);
            setOtpSent(true);
            setResendOtpTimer(60);
          } else {
            toast.error(yellowOwlMessages.OTP_SEND_FAIL);
          }
        } else {
          toast.error(yellowOwlMessages.USER_NOT_FOUND);
        }
      } else if (loginMode === 'email') {
        const response = await yellowOwlApi?.checkIfVerifiedUser('123', inputValue);
        const isVerified = response?.data?.isVerifiedUser;

        if (isVerified) {
          const otpResponse = await yellowOwlApi?.generateOTPForPhoneNumber({ email: inputValue });
          if (otpResponse) {
            toast.success(yellowOwlMessages.OTP_SENT);
            setOtpSent(true);
            setResendOtpTimer(60);
          } else {
            toast.error(yellowOwlMessages.OTP_SEND_FAIL);
          }
        } else {
          toast.error(yellowOwlMessages.USER_NOT_FOUND);
        }
      }
    },
  });

  const [{ isLoading: isLoginLoading }, handleLogin] = useAsync({
    fn: async () => {
      let redirectPath = '/magazines';
      try {
        if (loginMode === 'phoneNumber') {
          const fullPhoneNumber = `${selectedCountryCode}${inputValue}`;
          const response = await yellowOwlApi?.checkIfVerifiedUser(fullPhoneNumber);

          if (response?.data?.isVerifiedUser) {
            const signInResponse = await yellowOwlApi?.signIn({
              password,
              phoneNumber: fullPhoneNumber,
            });
            updateLocalStorage(PHONE_NUMBER_KEY, fullPhoneNumber);
            // Check for 500 status code and handle the error
            if (signInResponse?.status === 500) {
              throw new Error(yellowOwlMessages.INVALID_CREDENTIALS);
            } else if (signInResponse?.status === 200 || signInResponse?.status === 201) {
              updateLocalStorage(LOGIN_MODE, 'phoneNumber');
              updateLocalStorage(TOKEN_KEY, signInResponse.data.token);
              if (localStorage.getItem('isAssessment') === 'true') {
                navigate('/assessment/library');
              }
            } else {
              throw new Error(yellowOwlMessages.LOGIN_FAILED);
            }
          } else {
            toast.error(yellowOwlMessages.USER_NOT_REGISTERED);
          }
        } else if (loginMode === 'email') {
          const response = await yellowOwlApi?.checkIfVerifiedUser('123', inputValue);

          if (response?.data?.isVerifiedUser) {
            const signInResponse = await yellowOwlApi?.signIn({
              password,
              email: inputValue,
            });
            updateLocalStorage(EMAIL_KEY, inputValue);
            if (signInResponse?.status === 500) {
              throw new Error(yellowOwlMessages.INVALID_CREDENTIALS);
            } else if (signInResponse?.status === 200 || signInResponse?.status === 201) {
              updateLocalStorage(LOGIN_MODE, 'email');
              updateLocalStorage(TOKEN_KEY, signInResponse.data.token);
              if (localStorage.getItem('isAssessment') === 'true') {
                navigate('/assessment/library');
              }
            } else {
              throw new Error(yellowOwlMessages.LOGIN_FAILED);
            }
          } else {
            toast.error(yellowOwlMessages.USER_NOT_REGISTERED);
          }
        } else if (loginMode === 'rollNumber') {
          const response = await yellowOwlApi?.checkIfValidUser({ rollNumber: inputValue });

          if (response?.data?.message === 'Valid user') {
            const userDetailsResponse = await yellowOwlApi?.getUserDetails(undefined, undefined, inputValue);
            const userDetails = userDetailsResponse?.data;

            if (userDetails) {
              updateLocalStorage(ROLL_NUMBER, inputValue);
              storeUserDetails(
                userDetails,
                userDetails.parentPhoneNumber ?? null,
                userDetails.parentMailId ?? null,
                selectedCountryCode,
              );
              updateLocalStorage(LOGIN_MODE, 'rollNumber');
              updateLocalStorage(TOKEN_KEY, 'SampleToken');
              if (localStorage.getItem('isAssessment') === 'true') {
                navigate('/assessment/library');
              }
            }
          } else {
            toast.error(yellowOwlMessages.USER_NOT_REGISTERED);
          }
        }
      } catch (error) {
        // Catch both network errors and status code errors
        const errorMessage =
          (error instanceof Error && (error as any)?.response?.data?.message) || yellowOwlMessages.SOMETHING_WENT_WRONG;
        toast.error(errorMessage);
      }
    },
  });

  const [{ isLoading: isSignUpLoading }, handleSignUp] = useAsync({
    fn: async () => {
      try {
        const calculateUserAge = () => {
          const today = dayjs();
          const birthDate = dayjs(userDetail?.dob);
          return today.diff(birthDate, 'year');
        };
        const userId = localStorage.getItem('userId') ?? '';
        const storedLoginMode = localStorage.getItem('loginMode') as 'phoneNumber' | 'email' | 'rollNumber' | null;
        const writableUser = {
          userStrapiId: userId,
          parentPhoneNumber:
            storedLoginMode === 'email' ? userDetail?.phoneNumber : localStorage.getItem('phoneNumber')?.toString()!,
          userName: userDetail?.name!,
          dateOfBirth: userDetail?.dob!,
          userAge: calculateUserAge(),
          parentMailId:
            storedLoginMode === 'phoneNumber' ? userDetail?.email : localStorage.getItem('parentEmail')?.toString()!,
          loginType: storedLoginMode ?? undefined,
          isUpdate: true,
        };
        await yellowOwlApi?.addUserDetails(writableUser);
        let userDetailsResponse;
        if (storedLoginMode === 'phoneNumber') {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(localStorage.getItem('phoneNumber')?.toString()!);
        } else if (storedLoginMode === 'email') {
          userDetailsResponse = await yellowOwlApi?.getUserDetails(
            undefined,
            localStorage.getItem('email')?.toString()!
          );
        }
        const userDetails = userDetailsResponse?.data;
        if (userDetails) {
          storeUserDetails(
            userDetails,
            userDetails.parentPhoneNumber ?? null,
            userDetails.parentMailId ?? null,
            selectedCountryCode
          );
          setIsLoggedIn(true);
        } else {
          throw new Error(yellowOwlMessages.USER_DETAILS_NOT_FOUND);
        }
        if (localStorage.getItem('isAssessment') === 'true') {
          navigate('/assessment/library');
        }
      } catch (error) {
        toast.error(yellowOwlMessages.SOMETHING_WENT_WRONG);
      }
    },
  });

  const handleForgotPassword = async () => {
    setForgotPasswordMode(true);
  };
  const getHeading = () => {
    if (showLoginOptions) return yellowOwlMessages.LOGIN_TO_YOUR_ACCOUNT;
    if (showSignUpOptions) return yellowOwlMessages.SIGN_UP_FOR_AN_ACCOUNT;
    if (forgotPasswordMode) return yellowOwlMessages.FORGOT_PASSWORD;
    if (setPasswordScreen) return yellowOwlMessages.SET_YOUR_PASSWORD;
    if (additionalFields) return yellowOwlMessages.COMPLETE_YOUR_PROFILE;
    return yellowOwlMessages.LOGIN_REGISTER;
  };

  return {
    loginMode,
    setLoginMode,
    inputValue,
    setInputValue,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    showPassword,
    setShowPassword,
    showConfirmPassword,
    setShowConfirmPassword,
    isLoggedIn,
    setIsLoggedIn,
    showLoginOptions,
    setShowLoginOptions,
    showSignUpOptions,
    setShowSignUpOptions,
    selectedCountryCode,
    setSelectedCountryCode,
    otpSent,
    setOtpSent,
    otp,
    setOtp,
    additionalFields,
    setAdditionalFields,
    setPasswordScreen,
    setSetPasswordScreen,
    forgotPasswordMode,
    setForgotPasswordMode,
    passwordResetSuccess,
    setPasswordResetSuccess,
    resendOtpTimer,
    setResendOtpTimer,
    pathClick,
    setPathClick,
    userDetail,
    setUserDetail,
    handleCountryCodeChange,
    handlePathClick,
    isValidAge,
    handleSendOtp,
    handleResendOtp,
    handleVerifyOtp,
    handleVerifyOtpEmail,
    handleSetPassword,
    handleForgotPassword,
    handleForgotPasswordVerify,
    handleLogin,
    handleSignUp,
    getHeading,
    isOtpLoading,
    isOtpEmailLoading,
    isResendOtpLoading,
    isVerifyOtpLoading,
    isVerifyOtpEmailLoading,
    isSetPasswordLoading,
    isForgotPasswordVerifyLoading,
    isLoginLoading,
    isSignUpLoading,
  };
};
