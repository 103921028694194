import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, SxProps, Theme } from '@mui/material';

type SkillProgressData = {
  skillName: string;
  totalActivities: number;
  completedActivities: number;
  color: string;
};

type MyProgressProps = {
  skills: SkillProgressData[];
};

const progressContainerStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: 'white',
  padding: '10px',
};

const circularProgressContainerStyle: SxProps<Theme> = {
  position: 'relative',
  display: 'inline-flex',
  marginRight: 2,
};

const circularProgressLabelStyle: SxProps<Theme> = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const skillNameTextStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '16px',
  fontWeight: 'bold',
};

const progressPercentageTextStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '10px',
  fontWeight: 'bold',
};

const headerTextStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  // fontSize: '18px',
  fontWeight: 'bold',
};

export const MyProgress: React.FC<MyProgressProps> = ({ skills }) => {
  return (
    <Box>
      <Box>
        <Typography variant='h5' sx={headerTextStyle}>
          My Progress
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '20px',
        }}
      >
        {skills.map((skill) => (
          <SkillProgress
            key={skill.skillName}
            skillName={skill.skillName}
            totalActivities={skill.totalActivities}
            completedActivities={skill.completedActivities}
            color={skill.color}
          />
        ))}
      </Box>
    </Box>
  );
};

const SkillProgress: React.FC<SkillProgressData> = ({ skillName, totalActivities, completedActivities, color }) => {
  const [progress, setProgress] = useState(0);
  const targetValue = Math.round((completedActivities / totalActivities) * 100);
  const increment = 0.5;
  const duration = 500;

  useEffect(() => {
    const interval = (duration / targetValue) * increment;
    if (progress < targetValue) {
      const timer = setTimeout(() => {
        setProgress((prev) => Math.min(prev + 1, targetValue));
      }, interval);

      return () => clearTimeout(timer);
    }
  }, [progress, targetValue]);

  return (
    <Box sx={progressContainerStyle}>
      <Box sx={circularProgressContainerStyle}>
        <CircularProgress sx={{ color }} variant='determinate' value={progress} />
        <Box sx={circularProgressLabelStyle}>
          <Typography sx={progressPercentageTextStyle} variant='caption' component='div'>
            {`${targetValue}%`}
          </Typography>
        </Box>
      </Box>

      <Box>
        <Typography sx={skillNameTextStyle} variant='h6'>
          {skillName}
        </Typography>
        {/* <Typography sx={skillInfoTextStyle} variant='body2'>
          You finished {completedActivities}/{totalActivities} activities under the {skillName} skill
        </Typography> */}
      </Box>
    </Box>
  );
};
