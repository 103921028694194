import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  AppBar,
  IconButton,
  Toolbar,
  useTheme,
  useMediaQuery,
  Avatar,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  Theme,
  SxProps,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import MenuIcon from '@mui/icons-material/Menu';
import backGroundImage from '../../assets/images/bg-image-user-profile.svg';
import CloseIcon from '@mui/icons-material/Close';
import { UserProfileComponent } from '../common/UserProfileComponent';
import profilePicture from '../../assets/images/profile-picture.png';
import { useLocalStorage } from '../../context/LocalStorageContext';
import ConfirmationModal from '../common/confirmation-modal/confirmation-modal';
import CircularProgress from '@mui/material/CircularProgress';
import { AssessmentQuestionResponse } from '@yellow-owl/client-sdk/dist/api';
import { AssessmentVoiceInput } from '../../containers/assessments/AssessmentVoiceInput';
import LogoutComponent from '../common/LogoutComponent';

type AssessmentQuestionProps = {
  isLoading: boolean;
  hasError: boolean;
  questionData: AssessmentQuestionResponse | undefined;
  assessmentDone: boolean;
  onNext: () => void;
  onPrevious: () => void;
  hasPrevious: boolean;
  onAnswer: (answer: string) => void;
  selectedOptions: { [key: number]: string | null };
  setSelectedOptions: React.Dispatch<React.SetStateAction<{ [key: number]: string | null }>>;
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  questionHistory: QuestionWithAnswer[];
  handleOptionChange: (questionId: number, option: string) => void;
  isEditable: boolean;
};

interface QuestionWithAnswer extends AssessmentQuestionResponse {
  answer?: string;
  audioUrl?: string;
  index: number;
}

type MultipleChoiceQuestionProps = {
  question: AssessmentQuestionResponse;
  selectedOption: string | null;
  onOptionChange: (option: string) => void;
  isEditable: boolean;
};

const assessmentButtonStyles: SxProps<Theme> = {
  backgroundColor: '#46B8B0',
  color: '#fff',
  textTransform: 'none',
  marginTop: 2,
  borderRadius: '8px',
  '&:hover': { backgroundColor: '#38a69f' },
};

const drawerStyles = (isMobile: boolean, drawerWidth: number): SxProps<Theme> => ({
  width: isMobile ? '90%' : drawerWidth,
});

const drawerContentStyles = (isMobile: boolean, drawerWidth: number): SxProps<Theme> => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: isMobile ? '100%' : drawerWidth,
  marginTop: '80px',
});

const welcomeMessageStyles: SxProps<Theme> = {
  textAlign: 'center',
  color: '#666',
  padding: '16px',
  marginTop: '16px',
  fontSize: '0.9rem',
  backgroundColor: 'transparent',
};

const avatarStyles: SxProps<Theme> = {
  width: 120,
  height: 120,
  mt: 2,
  mb: 2,
};

const listStyles = (drawerWidth: number): SxProps<Theme> => ({
  width: drawerWidth,
  marginTop: '20px',
  '& .MuiListItemButton-root': {
    marginBottom: '8px',
  },
});

const listItemButtonStyles = (selected: boolean): SxProps<Theme> => ({
  borderRadius: '8px',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#46B8B0',
    color: 'white',
    transform: 'scale(1.02)',
    boxShadow: '0 4px 8px rgba(70, 184, 176, 0.2)',
  },
  backgroundColor: selected ? '#46B8B0' : 'transparent',
  color: selected ? 'white' : 'inherit',
  padding: '12px 16px',
});

const closeButtonStyles: SxProps<Theme> = {
  position: 'absolute',
  top: 12,
  right: 0,
};

const appBarStyles: SxProps<Theme> = {
  backgroundColor: '#46B8B0',
  position: 'sticky',
  top: 0,
  zIndex: 3,
};

const toolbarStyles: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px 16px',
};

const headerTypographyStyles: SxProps<Theme> = {
  flexGrow: 1,
  fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
};

const AssessmentQuestion: React.FC<AssessmentQuestionProps> = ({
  isLoading,
  hasError,
  questionData,
  assessmentDone,
  onNext,
  onPrevious,
  hasPrevious,
  onAnswer,
  selectedOptions,
  drawerOpen,
  setDrawerOpen,
  handleOptionChange,
  isEditable,
}) => {
  const [openFinishAssessmentModal, setOpenFinishAssessmentModal] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { state } = useLocalStorage();
  const userName = state.name;
  const drawerWidth = 300;

  const handleSubmitAnswer = () => {
    if (questionData && isEditable) {
      const answer: any = selectedOptions[questionData.questionId];
      onAnswer(answer);
    }
  };
  const handleAudioUrlChange = (url: string) => {
    if (questionData) {
      (questionData as QuestionWithAnswer).audioUrl = url;
    }
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleHomeButtonClick = () => {
    localStorage.removeItem('assessmentData');
    navigate('/assessment/library');
  };

  return (
    <Box sx={{ display: 'flex', height: '100vh', backgroundImage: `url(${backGroundImage})` }}>
      <Drawer
        variant={isMobile ? 'temporary' : 'persistent'}
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={drawerStyles(isMobile, drawerWidth)}
      >
        <Box sx={drawerContentStyles(isMobile, drawerWidth)}>
          <Avatar src={profilePicture} sx={avatarStyles} />
          <Typography variant='h4'>{userName}</Typography>
          <Typography sx={welcomeMessageStyles}>
            Welcome back, young explorer! 🌟 Ready to embark on another exciting learning adventure?
          </Typography>
        </Box>
        <List sx={listStyles(drawerWidth)}>
          <ListItemButton href='/#/assessment/library' sx={listItemButtonStyles(true)}>
            <ListItemText primary='Assessments' />
          </ListItemButton>
        </List>
        <Box sx={{ marginTop: 'auto', padding: '16px' }}>
          <LogoutComponent />
        </Box>
        <Button onClick={toggleDrawer} sx={closeButtonStyles}>
          <CloseIcon />
        </Button>
      </Drawer>

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginLeft: isMobile ? '0' : drawerOpen ? `0` : `-300px`,
          transition: 'margin 0.3s, width 0.3s',
        }}
      >
        <AppBar position='static' sx={appBarStyles}>
          <Toolbar sx={toolbarStyles}>
            {isMobile && (
              <IconButton edge='start' color='inherit' sx={{ mr: 2 }} onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            {!isMobile && !drawerOpen && (
              <IconButton edge='start' color='inherit' sx={{ mr: 2 }} onClick={toggleDrawer}>
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant='h6' sx={headerTypographyStyles}>
              Assessments
            </Typography>
            <Typography variant='h6' sx={{ mr: 2, fontSize: { xs: '0.875rem', sm: '1rem', md: '1.25rem' } }}>
              Hiya, {userName}!
            </Typography>
            <UserProfileComponent />
          </Toolbar>
        </AppBar>

        <Box sx={{ p: 3, height: '100%' }}>
          {isLoading ? (
            <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h6' sx={{ textAlign: 'center', color: 'red' }}>
                <CircularProgress />
              </Typography>
            </Box>
          ) : assessmentDone ? (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant='h5' sx={{ textAlign: 'center', color: '#46B8B0', fontWeight: 'bold' }}>
                You finished the assessment! Great Work! <br /> Your results will be available soon.
              </Typography>
              <Button variant='contained' onClick={handleHomeButtonClick} sx={assessmentButtonStyles}>
                Home
              </Button>
            </Box>
          ) : hasError ? (
            <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h6' sx={{ textAlign: 'center', color: 'red' }}>
                An error occurred. Please try again later.
              </Typography>
            </Box>
          ) : (
            <>
              <Button
                startIcon={<ArrowBackIosNewIcon />}
                onClick={() => {
                  setOpenFinishAssessmentModal(true);
                }}
                sx={{ fontWeight: 'bold', color: '#46B8B0', border: '2px solid #46B8B0', borderRadius: '24px' }}
              >
                Back
              </Button>
              <ConfirmationModal
                title='Finish Assessment'
                content='Are you sure you want to finish the assessment?'
                open={openFinishAssessmentModal}
                onClose={(userResponse) => {
                  setOpenFinishAssessmentModal(false);
                  if (userResponse) {
                    navigate(-1);
                  }
                }}
              />
              {isLoading ? (
                <CircularProgress />
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                  {questionData &&
                    (questionData.questionType === 'MCQ' ? (
                      <MultipleChoiceQuestion
                        question={questionData}
                        selectedOption={selectedOptions[questionData.questionId] || null}
                        onOptionChange={(option) => handleOptionChange(questionData.questionId, option)}
                        isEditable={isEditable} // Pass isEditable to MultipleChoiceQuestion
                      />
                    ) : questionData.questionType === 'AUDIO' ? (
                      <Box
                        sx={{
                          width: { xs: '100%', sm: '600px', md: '800px' },
                          border: '1px solid grey',
                          padding: 2,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                          {questionData.question}
                        </Typography>
                        <AssessmentVoiceInput
                          questionId={questionData.questionId.toString()}
                          onAudioUrlChange={handleAudioUrlChange}
                        />
                        <Typography variant='body1' sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
                          Please start recording once you have read the question.
                        </Typography>
                      </Box>
                    ) : (
                      <Typography variant='h6'>Unsupported question type.</Typography>
                    ))}
                </Box>
              )}
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '40px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 3,
                    width: { xs: '100%', sm: '600px', md: '800px' },
                  }}
                >
                  <Button
                    startIcon={<ArrowBackIosNewIcon />}
                    onClick={onPrevious}
                    disabled={!hasPrevious || isLoading}
                    sx={{ fontWeight: 'bold', color: '#46B8B0', border: '2px solid #46B8B0', borderRadius: '24px' }}
                  >
                    Prev
                  </Button>
                  <Button
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => {
                      handleSubmitAnswer();
                      onNext();
                    }}
                    disabled={
                      (questionData?.questionType === 'MCQ' && !selectedOptions[questionData.questionId]) || isLoading
                    }
                    sx={{ fontWeight: 'bold', color: '#46B8B0', border: '2px solid #46B8B0', borderRadius: '24px' }}
                  >
                    Next
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const MultipleChoiceQuestion: React.FC<MultipleChoiceQuestionProps> = ({
  question,
  selectedOption,
  onOptionChange,
  isEditable, // Destructure isEditable prop
}) => {
  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onOptionChange(event.target.value);
  };

  const handleClearSelection = () => {
    onOptionChange('');
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: '40px' }}>
      <Box
        sx={{
          width: { xs: '100%', sm: '600px', md: '800px' },
          border: '1px solid grey',
          padding: '10px',
          borderRadius: '10px',
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          {question.question}
        </Typography>

        <FormControl component='fieldset' sx={{ width: '100%' }}>
          <RadioGroup value={selectedOption || ''} onChange={handleOptionChange} sx={{ mt: 3 }}>
            {question?.options?.map((option, index) => (
              <FormControlLabel
                key={index}
                value={option}
                control={<Radio />}
                label={option}
                disabled={!isEditable} // Disable radio buttons if the question is not editable
                sx={{
                  backgroundColor: selectedOption === option ? '#3b9b94' : 'transparent',
                  borderRadius: 1,
                  py: 1,
                  px: 2,
                  mb: 1,
                  '&:hover': { backgroundColor: '#46B8B0' },
                }}
              />
            ))}
          </RadioGroup>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 1,
            }}
          >
            <Button
              onClick={handleClearSelection}
              disabled={!isEditable} // Disable the "Clear Selection" button if the question is not editable
              sx={{ fontWeight: 'bold', color: '#46B8B0', border: '2px solid #46B8B0', borderRadius: '24px' }}
            >
              Clear Selection
              <DeleteIcon color='error' sx={{ ml: 1 }} />
            </Button>
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
};

export default AssessmentQuestion;
