import React, { useContext, useEffect, useState } from 'react';
import { YellowOwlContext } from '../../context/YellowOwlContext';
import { Navigate } from 'react-router-dom';
import { EXPLORE_MAGAZINES } from '../../route/router';
import { useUserAuthEvents } from '../../services/userLoggedInEvent';
import {
  AGE_KEY,
  CATEGORY_ID_KEY,
  COUNTRY_CODE_KEY,
  EMAIL_KEY,
  TENANT_ID_KEY,
  USER_ID_KEY,
  USER_NAME_KEY,
  PARENT_PHONE_KEY,
  DOB_KEY,
  CITY_KEY,
  COUNTRY_KEY,
  STATE_KEY,
  UID_KEY,
  useLocalStorage,
  USER_TYPE,
  AVATAR,
} from '../../context/LocalStorageContext';

export type AuthResponseResult = {
  isNewUser: boolean;
  strapiUserId?: string;
  name?: string;
};

export type AuthResponse = {
  result?: AuthResponseResult;
  isLoading: boolean;
  hasError: boolean;
};

export type ErrorResponse = {
  type: 'error';
};

export type AuthenticatedRouteProps = {
  component: React.ReactElement;
};

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ component }) => {
  const yellowOwlApi = useContext(YellowOwlContext)!;
  const { state, updateLocalStorage, getLocalStorage } = useLocalStorage();

  const [userInfo, setUserInfo] = useState<AuthResponse>({ isLoading: false, hasError: false });
  const { sendLoggedInEventState, sendLoggedInEvent } = useUserAuthEvents();

  const getUserDetails = async () => {
    try {
      setUserInfo({ ...userInfo, isLoading: true });
      const { phoneNumber, parentEmail, loginMode } = state;
      let result;
      if (loginMode === 'phoneNumber' && phoneNumber) {
        result = await yellowOwlApi?.getUserDetails(phoneNumber);
      } else if (loginMode === 'email' && parentEmail) {
        result = await yellowOwlApi?.getUserDetails(undefined, parentEmail);
      }
      let countryCode = getLocalStorage(COUNTRY_CODE_KEY);
      if (!countryCode) {
        countryCode = 'IN';
      }

      if (result) {
        setUserInfo({
          result: {
            isNewUser: false,
            strapiUserId: result.data.userStrapiId,
            name: result.data.userName,
          },
          isLoading: false,
          hasError: false,
        });

        const {
          tenantId,
          commonCategoryId,
          userStrapiId,
          userAge,
          userName,
          parentMailId,
          city,
          country,
          dateOfBirth,
          state,
          parentPhoneNumber,
          uid,
          userType,
          avatar,
        } = result.data;

        // Store data in localStorage using custom methods
        if (avatar) {
          updateLocalStorage(AVATAR, avatar);
        }
        if (tenantId) {
          updateLocalStorage(TENANT_ID_KEY, tenantId);
        }
        if (commonCategoryId) {
          updateLocalStorage(CATEGORY_ID_KEY, commonCategoryId);
        }
        if (userStrapiId) {
          updateLocalStorage(USER_ID_KEY, userStrapiId);
        }
        if (userAge) {
          updateLocalStorage(AGE_KEY, userAge.toString());
        }
        if (userName) {
          updateLocalStorage(USER_NAME_KEY, userName);
        }
        if (parentMailId) {
          updateLocalStorage(EMAIL_KEY, parentMailId);
        }
        if (city) {
          updateLocalStorage(CITY_KEY, city);
        }
        if (country) {
          updateLocalStorage(COUNTRY_KEY, country);
        }
        if (dateOfBirth) {
          updateLocalStorage(DOB_KEY, dateOfBirth);
        }
        if (state) {
          updateLocalStorage(STATE_KEY, state);
        }
        if (parentPhoneNumber) {
          updateLocalStorage(PARENT_PHONE_KEY, parentPhoneNumber);
        }
        if (uid) {
          updateLocalStorage(UID_KEY, uid);
        }
        if (userType) {
          updateLocalStorage(USER_TYPE, userType);
        }
      }
      await sendLoggedInEvent();
    } catch (e) {
      if ((e as any).response.status === 404) {
        setUserInfo({
          isLoading: false,
          hasError: false,
          result: {
            isNewUser: true,
          },
        });
      } else {
        setUserInfo({
          isLoading: false,
          hasError: false,
        });
      }
    }
  };
  useEffect(() => {
    if (sendLoggedInEventState.error) {
      console.error('Failed to send logged-in event:', sendLoggedInEventState.error);
      // Optionally, you can show a toast or notification to the user
    } else if (sendLoggedInEventState.isComplete && !sendLoggedInEventState.isLoading) {
      console.log('Logged-in event sent successfully');
    }
  }, [sendLoggedInEventState]);

  useEffect(() => {
    const fetchData = getUserDetails;
    if (state.phoneNumber) {
      if (state.token) {
        fetchData();
      }
    }
  }, [state.token]);

  return (
    <>
      {' '}
      {userInfo.result && <>{!userInfo.result.isNewUser ? component : <Navigate to={EXPLORE_MAGAZINES} replace />}</>}
    </>
  );
};
