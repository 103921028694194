import { Navigate, Outlet, useLocation } from 'react-router-dom';

export type GuardedRouteProps = {
  isRouteAccessible: boolean;
  redirectRoute: string;
  allowAssessmentRoutes?: boolean;
};

export const GuardedRoute: React.FC<GuardedRouteProps> = ({
  isRouteAccessible,
  redirectRoute,
  allowAssessmentRoutes = false,
}): JSX.Element => {
  const location = useLocation();
  const isAssessmentRoute = location.pathname.startsWith('/assessment');
  if (isAssessmentRoute && !allowAssessmentRoutes) {
    return <Navigate to='/assessment/login' replace />;
  }
  return isRouteAccessible ? <Outlet /> : <Navigate to={redirectRoute} replace />;
};
