import React, { useState } from 'react';
import { Badges } from '@yellow-owl/client-sdk';
import {
  Box,
  Typography,
  Avatar,
  Stack,
  Theme,
  SxProps,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import backgroundImage from '../../assets/images/bg-image-user-profile.svg';
import { AchievementsContainer } from '../../containers/achievements/AchievementsContainer';
import { LeaderboardContainer } from '../../containers/leaderboard/LeaderboardContainer';
import { MyProgressContainer } from '../../containers/skill-progress/MyProgressContainer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { CommunityWallContainer } from '../../containers/community-wall/CommunityWallContainer';
import LogoutComponent from '../../components/common/LogoutComponent';

const containerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  padding: 4,
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-around',
  backgroundImage: `url(${backgroundImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  // maxWidth: '100vw',
  // maxHeight:'100vh',
  gap: '2vw',
};
const headerStyle = {
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
};

const profileLeftContainerStyles = {
  padding: '2vw',
  width: '60vw',
  // marginLeft: '2vw',
  border: '1px solid lightgrey',
  borderRadius: '16px',
};

const profileRightContainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: '1px solid lightgrey',
  borderRadius: '16px',
  padding: '2vw',
  width: '40vw',
  // height: '100%',
};

interface BadgesWithCount extends Badges {
  count: number;
}

type UserProfilePageProps = {
  userDetails: { userName: string; userScore: number; avatar: string };
  groupedBadges?: BadgesWithCount[];
};

const wrapperContainerStyle = (badgeCount: number) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  ml: badgeCount < 5 ? 0 : '12px',
  mb: '12px',
  width: '100%',
});

const badgesContainer = () => ({
  p: '8px',
  display: 'flex',
  flexDirection: 'row',
  textAlign: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  height: 'auto',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const badgeStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100px',
  height: '140px',
  marginRight: '10px',
  position: 'relative',
};

const badgeIconStyle: SxProps<Theme> = {
  width: '70px',
  height: '70px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column-reverse',
};

const countCircleStyle: SxProps<Theme> = {
  alignSelf: 'flex-end',
  width: 20,
  height: 20,
  marginBottom: '-10px',
  borderRadius: '50%',
  backgroundColor: '#ff5722',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: 12,
  fontWeight: 'bold',
};

const LeftContainerTopUserAvatarStyles: SxProps<Theme> = {
  width: '60px',
  height: '60px',
  bgcolor: 'lightblue',
  borderRadius: '50%',
};

const homeButtonStyle: SxProps<Theme> = {
  top: '18px',
  left: '18px',
  width: 'auto',
  height: 'auto',
  marginBottom: '24px',
  backgroundColor: '#46B8B0',
  color: 'white',
  '&:hover': { backgroundColor: '#46B8B0' },
  '&:focus': { backgroundColor: '#46B8B0' },
};

const toggleButtonStyles: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  textTransform: 'none',
  fontSize: '14px',
  borderRadius: '24px',
  backgroundColor: 'white',
  color: '#46B8B0',
  margin: '0',
  overflow: 'hidden',
  '&:hover': {
    backgroundColor: '#46B8B0',
    color: 'white',
  },
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#46B8B0',
    overflow: 'hidden',
    margin: '0',
  },
};

const ToggleButtonGroupStyles: SxProps<Theme> = {
  fontSize: '14px',
  margin: '0',
  overflow: 'hidden',
};

const earnedBadgesTitleStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontWeight: 'bold',
};

const badgeInfoContainerStyle: SxProps<Theme> = {
  display: 'flex',
  margin: '10px',
  flexDirection: 'column',
  marginLeft: '10px',
  justifyContent: 'center',
};

const badgeTypeTextStyle: SxProps<Theme> = {
  fontFamily: 'RalewayRegular',
  fontSize: '14px',
  fontWeight: 'bold',
};

const noDataContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
};

export const UserProfilePage: React.FC<UserProfilePageProps> = ({ userDetails, groupedBadges }) => {
  const [selectedButton, setSelectedButton] = useState('leaderBoard');
  const [isProfilePage, setIsProfilePage] = useState(true);
  if (!isProfilePage) {
    setIsProfilePage(false);
  }
  const navigate = useNavigate();
  const handleHomeButtonClick = () => {
    navigate('/');
  };
  return (
    <Box sx={containerStyle}>
      <Box sx={profileLeftContainerStyles}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'auto',
            width: 'auto',
            borderRadius: '24px',
          }}
        >
          <ToggleButtonGroup
            sx={ToggleButtonGroupStyles}
            value={selectedButton}
            exclusive
            onChange={(event, newSelection) => {
              setSelectedButton(newSelection);
            }}
            aria-label='date selection'
          >
            <ToggleButton sx={toggleButtonStyles} value='leaderBoard' aria-label='this week'>
              Leader Board
            </ToggleButton>
            <ToggleButton sx={toggleButtonStyles} value='communitywall' aria-label='this month'>
              Community Wall
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '97%',
            overflowY: 'auto',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
        >
          {selectedButton === 'leaderBoard' ? (
            <LeaderboardContainer />
          ) : (
            <CommunityWallContainer isProfilePage={isProfilePage} />
          )}
        </Box>
      </Box>
      <Box sx={profileRightContainerStyles}>
        <Box>
          <Box
            sx={{
              marginBottom: '36px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton sx={homeButtonStyle} aria-label='go home' component='label' onClick={handleHomeButtonClick}>
              <ArrowBackIcon />
            </IconButton>
            <Box sx={headerStyle}>
              <Typography variant='h4' sx={{ marginRight: '36px', fontWeight: 'bold', color: '#46B8B0' }}>
                Hi, {userDetails?.userName}
              </Typography>
              <Avatar alt={userDetails.userName} src={userDetails.avatar} sx={LeftContainerTopUserAvatarStyles} />
            </Box>
          </Box>
          <Box
            sx={{
              overflowY: 'auto',
              maxHeight: '60vh',
              scrollbarWidth: 'none',
              '&::-webkit-scrollbar': { display: 'none' },
            }}
          >
            <MyProgressContainer />
            {/* Progress Section */}
            <Box mt={3}>
              <Typography variant='h5' sx={earnedBadgesTitleStyle}>
                My Badges
              </Typography>
              <Stack direction='row' spacing={2}>
                {groupedBadges && groupedBadges.length > 0 ? (
                  <Box sx={wrapperContainerStyle(groupedBadges.length)}>
                    <Box sx={badgesContainer()}>
                      {groupedBadges.map((badge, index) => (
                        <Box sx={badgeStyle} key={index}>
                          <Box sx={badgeIconStyle}>
                            <img src={badge.badgeUrl} alt={badge.badgeType} style={{ width: '44px', height: '52px' }} />
                            {badge.count > 1 && <Box sx={countCircleStyle}>x{badge.count}</Box>}
                          </Box>
                          <Box sx={badgeInfoContainerStyle}>
                            <Typography sx={badgeTypeTextStyle}>
                              {badge.badgeType
                                .split('_')
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                .join(' ')}
                            </Typography>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ) : (
                  <Box sx={noDataContainerStyle}>
                    <Typography sx={{ color: '#625B77' }}>No badges earned yet</Typography>
                  </Box>
                )}
              </Stack>
            </Box>
            <Box mt={3}>
              <AchievementsContainer />
            </Box>
          </Box>
        </Box>
        <Box>
          <LogoutComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default UserProfilePage;
