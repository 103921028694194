import { SxProps, Box, IconButton, Tooltip } from '@mui/material';
import { useState, useRef, useCallback } from 'react';
import { Theme } from '@mui/material/styles';
import ConfirmationModal from '../../components/common/confirmation-modal/confirmation-modal';
import DeleteIcon from '@mui/icons-material/Delete';
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import SoundAnimation from '../../../src/assets/gif/sound-animated.gif';
import SoundBlack from '../../../src/assets/images/sound-black.png';
import SoundGray from '../../../src/assets/images/sound-gray.png';
import { BlobServiceClient } from '@azure/storage-blob';
import { uploadToAzureBlob } from '../../utils/azureBlob';

const voiceInputContainerStyle: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  width: '90%',
  margin: '0 auto',
};

const micBtnStyle: SxProps<Theme> = {
  backgroundColor: '#6AC6BF',
  borderRadius: '3px',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#6AC6BF',
  },
};

const audioPlayBtnStyle: SxProps<Theme> = {
  backgroundColor: '#6AC6BF',
  borderRadius: '3px',
  color: '#673ab7',
};

export function AssessmentVoiceInput({
  questionId,
  onAudioUrlChange,
}: {
  questionId: string;
  onAudioUrlChange: (url: string) => void;
}) {
  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState<string | null>(localStorage.getItem(`audio_${questionId}`));
  const [isPlaying, setIsPlaying] = useState(false);
  const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [showRecordBtn, setShowRecordBtn] = useState(true);
  const [recordingTimeout, setRecordingTimeout] = useState<NodeJS.Timeout | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [isValid] = useState<boolean>(true);
  console.log(isRecording);

  const handleStopRecording = useCallback(() => {
    setShowRecordBtn(true);
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
    }
    if (recordingTimeout) {
      clearTimeout(recordingTimeout);
      setRecordingTimeout(null);
    }
  }, [recordingTimeout]);

  const handleStartRecording = useCallback(async () => {
    setShowRecordBtn(false);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;
      const recorder = new MediaRecorder(stream);
      mediaRecorderRef.current = recorder;
      setAudioURL(null);
      const chunks: Blob[] = [];

      recorder.ondataavailable = (event) => {
        chunks.push(event.data);
      };

      recorder.onstop = async () => {
        const file = new File(chunks, 'audio.mp3', { type: 'audio/mp3' });
        if (file) {
          const url = await uploadToAzureBlob(file);
          setAudioURL(url);
          localStorage.setItem(`audio_${questionId}`, url);
          onAudioUrlChange(url);
        }
      };

      recorder.start();
      setIsRecording(true);

      const timeout = setTimeout(() => {
        handleStopRecording();
      }, 120000);
      setRecordingTimeout(timeout);
    }
  }, [handleStopRecording, onAudioUrlChange, questionId]);

  const playAudio = useCallback(() => {
    if (audioURL) {
      if (!audioPlayerRef.current) {
        const audio = new Audio(audioURL);
        audio.addEventListener('ended', () => setIsPlaying(false));
        audioPlayerRef.current = audio;
      }
      if (isPlaying) {
        audioPlayerRef.current.pause();
      } else {
        audioPlayerRef.current.play();
      }
      setIsPlaying(!isPlaying);
    } else {
      console.error('No audio available to play.');
    }
  }, [audioURL, isPlaying]);

  const handleDelete = () => {
    setOpenConfirmationModal(true);
  };

  const handleConfirmationModalClose = async (userResponse: boolean) => {
    setOpenConfirmationModal(false);
    if (userResponse) {
      if (audioPlayerRef.current) {
        audioPlayerRef.current.pause();
        audioPlayerRef.current = null;
      }
      try {
        const blobName = audioURL?.split(`${process.env.REACT_APP_AZURE_CDN_BASE_URL}/`)[1];
        if (blobName) {
          const blobServiceClient = new BlobServiceClient(
            `https://${process.env.REACT_APP_AZURE_STORAGE_ACCOUNT}.blob.core.windows.net?${process.env.REACT_APP_AZURE_SAS_TOKEN}`
          );
          const containerClient = blobServiceClient.getContainerClient(process.env.REACT_APP_AZURE_CONTAINER_NAME!);
          const blockBlobClient = containerClient.getBlockBlobClient(blobName);
          await blockBlobClient.deleteIfExists();
        }
        setIsPlaying(false);
        setAudioURL(null);
        localStorage.removeItem(`audio_${questionId}`);
      } catch (e) {
        console.error('Error deleting file from Azure Blob:', e);
      }
    }
  };

  const voiceInputBoxStyle: SxProps<Theme> = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '5px',
    padding: '5px',
    border: isValid ? '4px solid #6AC6BF' : '4px solid red',
    outline: 'none',
    width: '90%',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  };

  return (
    <div style={{ margin: '2vh 0' }}>
      <Box sx={voiceInputContainerStyle}>
        <Box sx={voiceInputBoxStyle}>
          <IconButton sx={audioPlayBtnStyle} aria-label='mic' onClick={playAudio} disabled={!audioURL}>
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
            {isPlaying ? (
              <img src={SoundAnimation} alt='' width={'85%'} height={'auto'} />
            ) : (
              <img src={audioURL ? SoundBlack : SoundGray} alt='' width={'85%'} height={'auto'} />
            )}
          </Box>
          {!audioURL && (
            <>
              {showRecordBtn ? (
                <Tooltip title='Speak' placement='top'>
                  <IconButton sx={micBtnStyle} aria-label='Start' onClick={handleStartRecording}>
                    <MicIcon />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title='Stop' placement='top'>
                  <IconButton sx={micBtnStyle} aria-label='Stop' onClick={handleStopRecording}>
                    <StopCircleIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
          {audioURL && (
            <Tooltip title='Delete' placement='top'>
              <IconButton sx={micBtnStyle} aria-label='Delete' onClick={handleDelete}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <ConfirmationModal
        title='Delete Audio'
        content='Are you sure you want to delete this Audio?'
        open={openConfirmationModal}
        onClose={handleConfirmationModalClose}
      />
    </div>
  );
}
